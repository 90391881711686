import React, {useEffect, useState} from 'react';
import ModalWrapper from "./ModalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {actionsState as projectState} from "../Redux/project";
import {sendGetHugeImage, sendRedrawEvent} from "../Helpers/Events";
import ReactSlider from "react-slider";

const ImageBG = ()=>{

    const dispatch = useDispatch();
    const ImageBG = useSelector(state=>state.project.ImageBG);

    // console.log('ImageBG',ImageBG)
    const appendImg = async event=>{
        event.persist();

        if (event.target.files && event.target.files[0]) {
            dispatch(projectState.addPreloader());
            const file    = event.target.files[0];
            const reader  = new FileReader();

            reader.onloadend = function () {
                const img = new Image();
                img.src = reader.result;
                img.onload = function() {
                    dispatch(projectState.setImageBG({
                        dataUrl:reader.result,
                        scale:ImageBG.scale,
                        opacity:ImageBG.opacity,
                        posX:ImageBG.posX,
                        posY:ImageBG.posY,
                        width:img.naturalWidth,
                        height:img.naturalHeight,
                    }));
                    dispatch(projectState.decPreloader());
                };
            }

            if (file) {
                reader.readAsDataURL(file);
            } else {
                dispatch(projectState.setImageBG({
                    dataUrl:'',
                    scale:ImageBG.scale,
                    opacity:ImageBG.opacity,
                    posX:ImageBG.posX,
                    posY:ImageBG.posY,
                    width:0,
                    height:0,
                }));
                dispatch(projectState.decPreloader());
            }
        }
    }

    const handlerScale = (val)=>{
        dispatch(projectState.setImageBG({
            dataUrl:ImageBG.dataUrl,
            scale:val,
            opacity:ImageBG.opacity,
            posX:ImageBG.posX,
            posY:ImageBG.posY,
            width:ImageBG.width,
            height:ImageBG.height,
        }));
    }

    const handlerOpacity = (val)=>{
        dispatch(projectState.setImageBG({
            dataUrl:ImageBG.dataUrl,
            scale:ImageBG.scale,
            opacity:val,
            posX:ImageBG.posX,
            posY:ImageBG.posY,
            width:ImageBG.width,
            height:ImageBG.height,
        }));
    }

    const handlerPosX = (val)=>{
        dispatch(projectState.setImageBG({
            dataUrl:ImageBG.dataUrl,
            scale:ImageBG.scale,
            opacity:ImageBG.opacity,
            posX:val,
            posY:ImageBG.posY,
            width:ImageBG.width,
            height:ImageBG.height,
        }));
    }

    const handlerPosY = (val)=>{
        dispatch(projectState.setImageBG({
            dataUrl:ImageBG.dataUrl,
            scale:ImageBG.scale,
            opacity:ImageBG.opacity,
            posX:ImageBG.posX,
            posY:val,
            width:ImageBG.width,
            height:ImageBG.height,
        }));
    }

    const remove = ()=>{
        dispatch(projectState.setImageBG({
            dataUrl:'',
            scale:1000,
            opacity:50,
            posX:'center',
            posY:'center',
            width:ImageBG.width,
            height:ImageBG.height,
        }));
        dispatch(projectState.setModal(''));
    }

    const close = ()=>{
        dispatch(projectState.setModal(''));
    }

    useEffect(()=>{
    })

    return  <ModalWrapper
                onClose={()=>close()}
                onDelete={()=>remove()}
                title="Изображение плана"
            >
            <div className="modal-body">
                <div className="options-title">Изображение</div>
                {/*<img className={'bl-images-append-btn2-image'} src={ImageBG.dataUrl} />*/}
                <label className="bl-images-append-btn2">
                    {ImageBG.dataUrl==='' && <span className={'btn btn-icon'}>Добавить</span>}
                    {ImageBG.dataUrl!=='' && <span className={'btn btn-icon'}>Изменить</span>}
                    <input type="file" onChange={appendImg} />
                </label>

                <div className="options-title">Параметры</div>
                Масштаб:
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    min={1}
                    max={3000}
                    value={ImageBG.scale}
                    onChange={handlerScale}
                    renderThumb={(props, state) => <div {...props}>{/*state.valueNow*/}</div>}
                />
                <br />
                Сдвиг по вертикали:
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    min={-1000}
                    max={1000}
                    value={ImageBG.posY}
                    onChange={handlerPosY}
                    renderThumb={(props, state) => <div {...props}>{/*state.valueNow*/}</div>}
                />
                <br />
                Сдвиг по горизонтали:
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    min={-1000}
                    max={1000}
                    value={ImageBG.posX}
                    onChange={handlerPosX}
                    renderThumb={(props, state) => <div {...props}>{/*state.valueNow*/}</div>}
                />
                <br />
                Прозрачность:
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    min={0}
                    max={100}
                    value={ImageBG.opacity}
                    onChange={handlerOpacity}
                    renderThumb={(props, state) => <div {...props}>{/*state.valueNow*/}</div>}
                />
            </div>
        </ModalWrapper>
}

export default ImageBG
