import { TrueColor } from '@tarikjabiri/dxf';

export { drawPolylines, drawLine, drawArc, drawText, drawSizes, drawHatches, drawImage, drawCircle } from './primitives';
export { rotatePoint, squaredDistance, thirdRectPoint, segmentLine, extrudeLine, calculateAngle, movePoint, splitText } from './geometry';
export { drawModules } from './drawModules';
export { drawPaths } from './drawPaths';
export { drawObject } from './drawObject';
export { drawColumns } from './drawColumns';
export { drawInfo, info } from './drawInfo';

export { fixDXF } from './fixDXF';
export { saveFile, getImageInfo } from './saveFile';

export const DIM_SIZE = 64;
export const SMALL_DIM_SIZE = 32;
export const BIG_DIM_SIZE = 128;
export const HUGE_DIM_SIZE = 256;
export const DIM_DEC = 0;
export const COLOR = {
    ORANGE: TrueColor.fromRGB(255, 130, 0),
    RED: TrueColor.fromRGB(255, 0, 0),
    GREEN: TrueColor.fromRGB(0, 255, 0),
    BLUE: TrueColor.fromRGB(0, 0, 255),
    GRAY: TrueColor.fromRGB(129, 129, 129),
}

export const getNextIndex = (array, index) => (index === array.length - 1) ? 0 : (index + 1);

export const algorithmOptions = [
    { label: 'Стандартный', value: 'standard' },
    { label: 'Отдельными стенами', value: 'walls' },
];
export const settingsDxf = {
    showElectricity: true,
    showModules: true,
    showAreaFigures: false,
    algorithm: algorithmOptions[0].value,
}
