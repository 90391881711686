import React, { useEffect, useMemo, useRef, useState } from 'react';
import Column from '../Classes/Column';
import Link from '../Classes/Link';
import Node from '../Classes/Node';
import { actionsState as projectState, actionsState as ProjectState } from '../Redux/project';
import { connect, useDispatch, useSelector } from "react-redux";
import Projects from "./Projects";
import { Line3, Vector2, Vector3 } from "three";
import ModuleMenu from "./ModuleMenu";
import ModuleInfo from "./ModuleInfo";
import { getIntersection, is_touch_device, polyPoint } from "../Helpers/functions";
import FloorInfo from "./FloorInfo";
import { sendRedrawEvent, sendRedrawSimpleEvent, sendUnselectEvent } from "../Helpers/Events";
import { checkHoverColumn, getParallelEdgeLine3, initColumnPoints, stickColumnToWall } from './Utils/columnsCalculation';
import WallObjectInfo from "./WallObjectInfo";
import PointInfo from "./PointInfo";
import BWallInfo from "./BWallInfo";
import ImageBG from "./ImageBG";
import LinkInfo from "./LinkInfo";
import ContextMenu from "./ContextMenu";
import { ColumnInfo } from "./ColumnInfo";

import {
    calcAngleDeg,
    checkMatchNode,
    checkPointLiesOnSegment,
    getCommonPoint,
    removingZeroLengthLinks,
    removingZeroLengthWalls
} from './PlanEditor/helpers/';

import { drawGrid, drawLock, drawWallObjects, drawColumn } from './PlanEditor/draw/'
import { lengthBezierCurve } from "../Helpers/GeometryAlgoritm";
import { EstimateEdit, EstimateObject, ObjectInfo } from './Features/SideMenu';
import { colors, columnParams, isPointInsidePolygon } from './Utils';
import { Convert as ConvertWithParams } from './PlanEditor/helpers'
import { EstimateFormatterMode } from './Features/SideMenu/Estimate';
import { checkRoom } from './Features/SideMenu/Estimate/utils';
import { EditField, usePlanField } from './Features/Plan';
import { checkColumn } from './Features/SideMenu/Estimate/utils/checkRoom';
import { stickPointToWall } from './Utils/pointUtils';

let index = null;

const isInside = (point, points) => {
    const x = point.x;
    const y = point.y;

    let inside = false;

    for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
        let xi = points[i][0], yi = points[i][1];
        let xj = points[j][0], yj = points[j][1];

        let intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
}

const isInsideBoundingBox = (point, boundingBox) => {
    return point?.x >= boundingBox?.minX
        && point?.x <= boundingBox?.maxX
        && point?.y >= boundingBox?.minY
        && point?.y <= boundingBox?.maxY;

}

const rgbToHex = (r, g, b) => {
    if (r > 255 || g > 255 || b > 255)
        throw "Invalid color component";
    return ((r << 16) | (g << 8) | b).toString(16);
}

const selectedText = {
    diagonalCenter: null,
    boundingBox: null,
    readyToMove: false,
    height: 0,
    width: 0,
    getPosition: (cycle, diagonalCenter) => {
      return {
          x: diagonalCenter.x - selectedText.width / 2,
          y: diagonalCenter.y - selectedText.height / 2 - (cycle?.objTitle?.length ? selectedText.height : 0)
      }
    },
    calculateBoundingBox: (cycle, diagonalCenter, point) => {
        const textHeight = 25;
        const textWidth = Math.max(
            cycle?.objTitle?.length || 0,
            cycle?.squareText?.length || 0
        ) * 8;
        const rect = {
            minX: diagonalCenter?.x - textWidth / 2,
            maxX: diagonalCenter?.x + textWidth / 2,
            minY: diagonalCenter?.y - textHeight / 2 - (cycle?.objTitle?.length ? selectedText.height : 0),
            maxY: diagonalCenter?.y + textHeight / 2,
        }

        if (isInsideBoundingBox(point, rect)) {
            selectedText.diagonalCenter = cycle.diagonalCenter;
            selectedText.width = textWidth;
            selectedText.height = textHeight;
            selectedText.boundingBox = rect;
        } else {
            selectedText.diagonalCenter = null;
            selectedText.boundingBox = null;
        }

        return rect;
    },
    checkIsReadyToMove: (point) => {
        if (
            selectedText.boundingBox
            && isInsideBoundingBox(point, selectedText.boundingBox)
        ) {
            selectedText.readyToMove = true;
            document.body.style.cursor = 'move';
        } else {
            selectedText.readyToMove = false;
            document.body.style.cursor = 'default';
        }
    },
    move: (moveX, moveY) => {
        const deltaPoint = { x: moveX, y: moveY };
        selectedText.diagonalCenter.x += deltaPoint.x;
        selectedText.diagonalCenter.y += deltaPoint.y;
        selectedText.diagonalCenter.isMoved = true;
    },
    cancelMove: () => {
        selectedText.diagonalCenter = null;
        selectedText.boundingBox = null;
        selectedText.readyToMove = false;
        document.body.style.cursor = 'default';
    }
}

export const selectedRoom = {
    points: null, // точки выделенной комнаты
    cycle: null,
    parts: [],
    ready2Move: false, // флаг - готова к движению
    // showMenu: false, // флаг - надо ли показывать меню

    getPoints: (cycle) => { //берем точку и выделяем комнату в которую она входит
        if (selectedRoom.points) {
            selectedRoom.points.length = 0;
            selectedRoom.cycle = null
            selectedRoom.points.length = 0;
        }
        const points = cycle._points;

        const tempPoints = points.map(point => { return ([point.x, point.y]) });
        const jsonStringifyPoints = tempPoints.map(point => JSON.stringify(point));
        const setPoints = new Set(jsonStringifyPoints);
        const arrayPoints = Array.from(setPoints);

        selectedRoom.cycle = cycle;
        selectedRoom.points = Array.from(arrayPoints.map(point => JSON.parse(point)));
    },

    check: (point) => { //проверяем что точка входи в выбранную комнату
        if (selectedRoom.points) {
            return isInside(point, selectedRoom.points);
        }
        return false
    },

    ready2MoveFN: (point, plan, modules) => { //проверяем готовы ли перемещать комнату
        if (selectedRoom.check(point)) {
            selectedRoom.ready2Move = true;
            document.body.style.cursor = 'move';
            selectedRoom.parts = [];

            const activeObject = selectedRoom.cycle;

            plan.cycles.forEach((cycle) => {
                if (cycle.isFigure && cycle.points.every((point) => isPointInsidePolygon(point, activeObject.points))) {
                    selectedRoom.parts.push(cycle);
                }
            });

            modules.forEach((module) => {
                if (checkRoom(module, activeObject)) {
                    selectedRoom.parts.push(module);
                }
            });

            activeObject.links.forEach((link) => {
                plan.bWalls.forEach((wall) => {
                    if (wall.mainLink === link) {
                        selectedRoom.parts.push(wall);

                        if (plan.columns?.length) {
                            const addColumn = (columnIndex) => {
                                const currentColumn = plan.columns?.[columnIndex];
                                if (currentColumn) {
                                    selectedRoom.parts.push(currentColumn)
                                }
                            }
                            if (wall.leftCols?.length) wall.leftCols.forEach(addColumn);
                            if (wall.rightCols?.length) wall.rightCols.forEach(addColumn);
                        }
                    }
                })
            })

            plan.columns.forEach((column) => {
                if (checkColumn(column, activeObject) && !selectedRoom.parts.includes(column)) {
                    selectedRoom.parts.push(column);
                }
            });

            plan.links.forEach((link) => {
                if ((link.isFigure || link.isRuler) && isPointInsidePolygon(link.a, activeObject.points) && isPointInsidePolygon(link.b, activeObject.points)) {
                    selectedRoom.parts.push(link);
                }
            })
        } else {
            selectedRoom.ready2Move = false;
            document.body.style.cursor = 'default';
            selectedRoom.points = null;
            selectedRoom.cycle = null;
            selectedRoom.parts = [];
        }
    },

    move: (plan, modules, activeObject, moveX, moveY) => {
        const deltaPoint = {
            x: moveX,
            y: moveY,
        }

        if (activeObject?.isCycle) {
            selectedRoom.parts.forEach((part) => {
                if (part.isCycle && part.isFigure) {
                    part.diagonalCenter.x += deltaPoint.x;
                    part.diagonalCenter.y += deltaPoint.y;
                    part.texRect.x += deltaPoint.x;
                    part.texRect.y += deltaPoint.y;
                }
                if (part.isWall) {
                    const wall = part;

                    wall.nodes.forEach((node) => {
                        node.x += deltaPoint.x;
                        node.y += deltaPoint.y;
                    });

                    if (wall.isBezier) {
                        wall.outlineBezier.forEach((bezier) => {
                            bezier.points[0].x += deltaPoint.x;
                            bezier.points[0].y += deltaPoint.y;
                            bezier.points[1].x += deltaPoint.x;
                            bezier.points[1].y += deltaPoint.y;
                            bezier.points[2].x += deltaPoint.x;
                            bezier.points[2].y += deltaPoint.y;
                            bezier.points[3].x += deltaPoint.x;
                            bezier.points[3].y += deltaPoint.y;
                        });
                        wall.inlineBezier.forEach((bezier) => {
                            bezier.points[0].x += deltaPoint.x;
                            bezier.points[0].y += deltaPoint.y;
                            bezier.points[1].x += deltaPoint.x;
                            bezier.points[1].y += deltaPoint.y;
                            bezier.points[2].x += deltaPoint.x;
                            bezier.points[2].y += deltaPoint.y;
                            bezier.points[3].x += deltaPoint.x;
                            bezier.points[3].y += deltaPoint.y;
                        });
                        wall.bezier.points.forEach((point) => {
                            point.x += deltaPoint.x;
                            point.y += deltaPoint.y;
                        });
                        wall.bezierControlPoint_1A.x += deltaPoint.x;
                        wall.bezierControlPoint_1A.y += deltaPoint.y;
                        wall.bezierControlPoint_1B.x += deltaPoint.x;
                        wall.bezierControlPoint_1B.y += deltaPoint.y;

                        wall.inlineBezierLUT = wall.inlineBezier.map((bezier) => bezier.getLUT(32).map((lut) => ({
                            ...lut,
                            x: lut.x + deltaPoint.x,
                            y: lut.y + deltaPoint.y,
                        })));
                    }
                }
                if (part.isColumn) {
                    const column = part;
                    column.x += deltaPoint.x;
                    column.y += deltaPoint.y;
                }
                if (!part.isCycle && (part.isFigure || part.isRuler)) {
                    const link = part;
                    link.a.x += deltaPoint.x;
                    link.b.x += deltaPoint.x;
                    link.a.y += deltaPoint.y;
                    link.b.y += deltaPoint.y;
                }
                if (part.isModule) {
                    const module = part;
                    module.position.x += deltaPoint.x;
                    module.position.y += deltaPoint.y;
                }
            })

            activeObject.diagonalCenter.x += deltaPoint.x;
            activeObject.diagonalCenter.y += deltaPoint.y;
            activeObject.texRect.x += deltaPoint.x;
            activeObject.texRect.y += deltaPoint.y;
        }
    },

    moveCancel: () => {
        selectedRoom.ready2Move = false;
        selectedRoom.points = null;
        selectedRoom.cycle = null;
        selectedRoom.parts = [];
        document.body.style.cursor = 'default';
    },
}

let angles = {};

const PlanEditor = (props) => {
    const ref = useRef(null);
    const refFake = useRef(null);
    const refCanvasImageBG = useRef(null);
    const dispatch = useDispatch();
    const updateFlag = useSelector(store => store.modules.updateModuleFlag);
    const filters = useSelector(state => state.project.filters);
    const tool = useSelector(state => state.project.tool);
    const snap = useSelector(state => state.project.snap);
    const enableCreateWalls = useSelector(store => store.project.userSetup.enableCreateWalls);
    const ImageBGData = useSelector(state => state.project.ImageBG);
    const steps = useSelector(state => state.project.steps);
    const showEstimated = useSelector(state => state.project.showEstimated);
    const level = useSelector(state => state.project.level);
    const cancelTouch = useSelector(state => state.project.cancelTouch);

    const { value, position, object, onChange, clearField } = usePlanField();

    const [_activeObject, setActiveObject] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

    const timerRef = useRef(0);
    const contextMenu = useRef(null);

    let activeObject = null;
    let prevTouch = null;

    useEffect(() => {
        const plan = props.plan;

        !enableCreateWalls && plan.setPlanMode('view');

        const setMoveTool = () => {
            dispatch(projectState.setTool('move'));
            dispatch(projectState.setModal('move'));
            plan.setPlanMode('move');
        }

        //стартует при монтаже компонента
        const canvas = ref.current;
        const canvasFake = refFake.current;
        const ctx = canvas.getContext('2d');
        const ctxFake = canvasFake.getContext('2d');

        const width = window.innerWidth
        const height = window.innerHeight

        let { devicePixelRatio: ratio = 1 } = window;
        ratio = ratio < 1 ? 2 - ratio : ratio;
        // const ratio = 1
        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = ctx.width = width * ratio
            canvas.height = ctx.height = height * ratio
            ctx.scale(ratio, ratio)

            canvasFake.width = ctxFake.width = width * ratio
            canvasFake.height = ctxFake.height = height * ratio
            ctxFake.scale(ratio, ratio)
        }

        //стэйт для зума,таскания и состояния клика
        let offsetX = plan.offsetX;
        let offsetY = plan.offsetY;

        if (!plan.offsetX || !plan.offsetY) {
            offsetX = (canvas.width / 2) - ((canvas.width / 2) / ratio);
            offsetY = (canvas.height / 2) - ((canvas.height / 2) / ratio);
        }
        if (plan.ratio !== ratio) {
            plan.offsetX = offsetX
            plan.offsetY = offsetY
            plan.ratio = ratio
        }

        let zoom = .1;   // 0.02-4.0 в roomle
        let drag = false;   //флаг таскания плана
        let dragDistance = false;

        let rotate = false;
        let hoverObject = null;
        let clickObject = null;

        let clickMousePoint = null;
        let startAngle = null;
        let startPosition = null;

        let columnSizing = false;
        let columnSizingDirection = false;
        let columnSizingPrev = 0;
        let isHoverColumn = false;
        let isContextMenu = false;

        let isWheelEvent = false;
        let touchPrevEvent = null;
        let scaling = false;
        let touchesZoom;
        let distance = 0;
        let lastDistance = 0;
        let moving = 0;
        let clickCanvas = false;
        let currentWall = false;

        let undoModulePrev = null;

        let infos = [];

        if (plan.zoom !== zoom) {
            zoom = plan.zoom;
        } else {
            plan.zoom = zoom;
        }

        const Convert = {
            toPixel: (p) => {    //на вход вектор в см на выход в px
                const x = Math.ceil(ctx.width / 2 + p.x * zoom - offsetX)
                const y = Math.ceil(ctx.height / 2 + p.y * zoom - offsetY)
                return new Node(x, y)
            },
            toSM: (p) => {     //на вход вектор в px на выход в см
                const x = Math.ceil((p.x - ctx.width / 2 + offsetX) / zoom)
                const y = Math.ceil((-ctx.height / 2 + p.y + offsetY) / zoom)
                return new Node(x, y)
            },
            getCanvasCenterPixel: () => {
                const x = Math.ceil((ctx.width - (ctx.width / 2)) / ratio)
                const y = Math.ceil((ctx.height - (ctx.height / 2)) / ratio)
                return new Node(x, y)
            }
        }

        // console.log('plan.zoom',plan.zoom)
        ////////////////////////////////////////////////////////////////////////////////
        const handlerKeydown = (event) => {

            if (plan.mode === 'ruler')
                return

            if (activeObject && activeObject.type === 'node' && !activeObject.object.isLocked) {

                if (event.code === 'ArrowUp') {
                    activeObject.object.y -= steps
                    draw(ctx, plan)
                } else if (event.code === 'ArrowDown') {
                    activeObject.object.y += steps
                    draw(ctx, plan)
                } else if (event.code === 'ArrowLeft') {
                    activeObject.object.x -= steps
                    draw(ctx, plan)
                } else if (event.code === 'ArrowRight') {
                    activeObject.object.x += steps
                    draw(ctx, plan)
                }

            } else if (activeObject && activeObject.isModule) {
                let vPos
                if (event.code === 'ArrowUp') {
                    // console.log('activeObject.position',activeObject.position)
                    vPos = activeObject.position.clone()
                        .sub(new Vector2(
                            activeObject.position.x,
                            activeObject.position.y - steps
                        )) //вектор перемещения

                    activeObject.position = {
                        x: activeObject.position.x - vPos.x,
                        y: activeObject.position.y - vPos.y
                    };

                    // console.log('activeObject.position',activeObject.position)
                } else if (event.code === 'ArrowDown') {
                    vPos = activeObject.position.clone()
                        .sub(new Vector2(
                            activeObject.position.x,
                            activeObject.position.y + steps
                        )) //вектор перемещения

                    activeObject.position = {
                        x: activeObject.position.x - vPos.x,
                        y: activeObject.position.y - vPos.y
                    };
                } else if (event.code === 'ArrowLeft') {
                    vPos = activeObject.position.clone()
                        .sub(new Vector2(
                            activeObject.position.x - steps,
                            activeObject.position.y
                        )) //вектор перемещения

                    activeObject.position = {
                        x: activeObject.position.x - vPos.x,
                        y: activeObject.position.y - vPos.y
                    };
                } else if (event.code === 'ArrowRight') {
                    vPos = activeObject.position.clone()
                        .sub(new Vector2(
                            activeObject.position.x + steps,
                            activeObject.position.y
                        )) //вектор перемещения

                    activeObject.position = {
                        x: activeObject.position.x - vPos.x,
                        y: activeObject.position.y - vPos.y
                    };
                }
                // event.preventDefault();
                _setActiveObject({
                    object: activeObject
                });
                draw(ctx, plan);
            }
            if (activeObject && activeObject.isCycle && activeObject.isFloor) {
                if (event.code === 'ArrowUp') {
                    selectedRoom.move(plan, modules, activeObject, 0, -steps);
                }
                if (event.code === 'ArrowDown') {
                    selectedRoom.move(plan, modules, activeObject, 0, steps);
                }
                if (event.code === 'ArrowLeft') {
                    selectedRoom.move(plan, modules, activeObject, -steps, 0);
                }
                if (event.code === 'ArrowRight') {
                    selectedRoom.move(plan, modules, activeObject, steps, 0);
                }
                draw(ctx, plan);
            }
        }
        const handlerKeyUp = (event) => {
            if (event.code === 'ArrowUp'
                || event.code === 'ArrowDown'
                || event.code === 'ArrowLeft'
                || event.code === 'ArrowRight') {
                if (plan.mode === 'ruler') {
                    return
                }

                if (activeObject && activeObject.isModule) {
                    _setActiveObject({
                        object: activeObject
                    });
                    draw(ctx, plan);
                }
            }
        }
        ////////////////////////////////////////////////////////////////////////////////
        const setUndo = (obj) => {
            dispatch(projectState.addPreloader());
            plan.setActionUndo(obj);
            dispatch(projectState.decPreloader());
        }

        const handlerDown = (event) => {
            setShowMenu(false);
            clickCanvas = true;

            if (event.type === "touchstart") {
                event.preventDefault();
            }

            const distForMerge = Math.min(Math.max(3, 13 / zoom), 100);

            const touches = event.touches || event.changedTouches;

            if (event.type === "touchstart" && touches.length === 2) {
                touchesZoom = Convert.toSM(new Node((touches[0].clientX + touches[1].clientX) / 2, (touches[0].clientY + touches[1].clientY) / 2))

                scaling = true;
                lastDistance = Math.sqrt(
                    (touches[0].clientX - touches[1].clientX) *
                    (touches[0].clientX - touches[1].clientX) +
                    (touches[0].clientY - touches[1].clientY) *
                    (touches[0].clientY - touches[1].clientY)
                );
                // console.log('lastDistance',lastDistance);
                return;
            }

            if (event.button === 2 || (event.type === "touchstart" && touches.length === 2)) {
                removeColumn();
            }

            scaling = false;

            let point, pointWindow;
            if (event.type === 'touchstart') {
                if (event.changedTouches.length < 1) return;

                point = Convert.toSM(new Node(event.changedTouches[0].clientX, event.changedTouches[0].clientY));
                point.x = Math.round(point.x / 5) * 5;
                point.y = Math.round(point.y / 5) * 5;

                if (!scaling && !moving && !columnSizing) {
                    timerRef.current = setTimeout(() => handlerContext(event), 500);
                }
                touchPrevEvent = event;

            } else {
                point = Convert.toSM(new Node(event.clientX, event.clientY));

                point.x = Math.round(point.x / 5) * 5;
                point.y = Math.round(point.y / 5) * 5;

                pointWindow = new Node(event.clientX, event.clientY);
            }

            if (plan.mode === 'walls' || plan.mode === 'ruler' || plan.mode === 'figures' || plan.mode === 'leader') {
                if (event.button === 2) {
                    setActiveObject(null);
                    plan.setActiveObject(null);
                    setMoveTool();
                } else {
                    const [_dist, _node] = plan.nodes.reduce((dn, n) => {
                        const _d = distNodeToNode(point, n);
                        return _d < dn[0] ? [_d, n] : dn;
                    }, [Infinity, null]);
                    if (_dist < distForMerge) {
                        point.x = _node.x;
                        point.y = _node.y;
                    }

                    if (plan.mode === 'walls') {
                        point.isWall = true;
                    } else if (plan.mode === 'ruler') {
                        point.isRuler = true;
                    } else if (plan.mode === 'figures') {
                        point.isFigure = true;
                    } else if (plan.mode === 'leader') {
                        point.isLeader = true;
                    }

                    plan.putNode(point);
                    plan.dragNode = plan.virtualNode = point.clone();

                    if (plan.mode === 'walls') {
                        plan.virtualNode.isWall = true;
                    } else if (plan.mode === 'ruler') {
                        plan.virtualNode.isRuler = true;
                    } else if (plan.mode === 'figures') {
                        plan.virtualNode.isFigure = true;
                    } else if (plan.mode === 'leader') {
                        plan.virtualNode.isLeader = true;
                    }

                    plan.putNode(plan.virtualNode);

                    if (plan.mode === 'walls') {
                        plan.virtualLink = new Link(point, plan.virtualNode, 'wall');
                    } else if (plan.mode === 'ruler') {
                        plan.virtualLink = new Link(point, plan.virtualNode, 'ruler');
                    } else if (plan.mode === 'figures') {
                        plan.virtualLink = new Link(point, plan.virtualNode, 'figure');
                    } else if (plan.mode === 'leader') {
                        plan.virtualLink = new Link(point, plan.virtualNode, 'leader');
                    }

                    if (plan.bWalls.length > 0) {
                        plan.virtualLink.depth = plan.bWalls[plan.bWalls.length - 1].mainLink.depth;
                        plan.virtualLink.height = plan.bWalls[plan.bWalls.length - 1].mainLink.height;
                        plan.virtualLink.lrBuild = plan.bWalls[plan.bWalls.length - 1].mainLink.lrBuild;
                    }
                }
            } else if (plan.mode === 'move' || plan.mode === 'view') {
                if (
                    activeObject
                    && (activeObject.isModule || activeObject.isColumn)
                    && activeObject.isHoverRotateControl(point, zoom)
                    && plan.mode === 'move'
                ) {
                    const position = activeObject.position || new Node(activeObject.x, activeObject.y);

                    startAngle = activeObject.angle;
                    rotate = true;
                    clickMousePoint = point.clone();
                    startPosition = position.clone();

                    undoModulePrev = {
                        angel: activeObject.angle,
                        position: { x: position.x, y: position.y }
                    };

                    return;
                } else if (
                    activeObject
                    && activeObject.isColumn
                    && activeObject.isHoverSizingControl(point, zoom)
                    && plan.mode === 'move'
                ) {
                    const _columnSizing = activeObject.isHoverSizingControl(point, zoom);
                    if (_columnSizing.status) {
                        columnSizing = _columnSizing.status;
                        columnSizingDirection = _columnSizing.direction;
                        return;
                    }
                } else if (
                    activeObject
                    && activeObject.isModule
                    && plan.mode === 'move'
                ) {
                    let clickObjectModule = null;
                    props.modules.map(m => {
                        if (m.isHover(point)) clickObjectModule = m;
                    });
                    if (activeObject === clickObjectModule) { // Нажали на активный модуль
                        moving = true;
                        clickMousePoint = point.clone();
                        startPosition = activeObject.position.clone();

                        undoModulePrev = {
                            angel: activeObject.angle,
                            position: { x: activeObject.position.x, y: activeObject.position.y }
                        };

                        return;
                    }
                }

                if (activeObject && activeObject.isColumn && checkHoverColumn(activeObject, point)) {
                    isHoverColumn = true;
                    startPosition = point;
                }

                let [_dist, _node] = plan.nodes.reduce((dn, n) => {
                    let _d;
                    if (
                        n.isLocked || (
                            (!filters.walls && n.isWall) ||
                            (!filters.ruler && n.isRuler) ||
                            (!filters.floors && n.isFigure) ||
                            (!filters.ruler && n.isLeader)
                        )
                    ) {
                        _d = Infinity;
                    } else {
                        _d = distNodeToNode(point, n);
                    }

                    return _d < dn[0] ? [_d, n] : dn;
                }, [Infinity, null]);

                // Если выбрана стена, её точки всегда сверху остальных
                if (activeObject !== null && activeObject.isWall) {
                    [_dist, _node] = plan.nodes.reduce((dn, n) => {
                        let _d;
                        if (activeObject.nodes.includes(n) || activeObject.isBezier)
                            _d = distNodeToNode(point, n);
                        else
                            _d = Infinity;

                        return _d < dn[0] ? [_d, n] : dn;
                    }, [Infinity, null]);
                }

                if (_dist < distForMerge && !isHoverColumn) {
                    plan.dragNode = _node;
                    // console.log('_node', _node)
                }

                if (!plan.dragNode) drag = true;
            }
            // console.log(getCycleByPoint(pointWindow, 'floors'));
            // selectedRoom.getPoints(cycleFloorByPoint.cycle._points);

            if (plan.mode === 'move') {
                selectedRoom.ready2MoveFN(point, plan, modules);
                selectedText.checkIsReadyToMove(pointWindow || point);
            }

            if (plan.dragNode) {
                currentWall = plan.bWalls.find((wall) => wall.mainLink.a === plan.dragNode || wall.mainLink.b === plan.dragNode);
            }

            draw(ctx, plan);
        }

        const handlerUp = (event) => {
            currentWall = null;
            clearTimeout(timerRef.current);
            const prevActiveObject = activeObject;

            if (!clickCanvas) {
                clickCanvas = false
                return;
            }
            clickCanvas = false

            if (!dragDistance && !scaling) {

                if (selectedRoom.ready2Move) {
                    setUndo({ type: "plan" });
                    selectedRoom.moveCancel();
                    plan.setFloors('floor');
                    plan.resetMagnetization();
                    prevTouch = null;
                }

                if (selectedText.readyToMove || selectedText.diagonalCenter) {
                    selectedText.cancelMove();
                    dispatch(projectState.setModal(''));
                    prevTouch = null;
                }

                const distForMerge = Math.min(Math.max(3, 13 / zoom), 100);

                let point, pointWindow;
                if (event.type === 'touchend') {
                    // console.log('offsetX ',plan.offsetX);
                    if (event.changedTouches.length < 1)
                        return;
                    point = Convert.toSM(new Node(event.changedTouches[0].clientX, event.changedTouches[0].clientY));
                    pointWindow = new Node(event.changedTouches[0].clientX, event.changedTouches[0].clientY);

                } else {
                    point = Convert.toSM(new Node(event.clientX, event.clientY));
                    pointWindow = new Node(event.clientX, event.clientY);
                }

                let click_node = null;
                const [_dist, _node] = plan.nodes.reduce((dn, n) => {
                    const _d = distNodeToNode(point, n);
                    return _d < dn[0] ? [_d, n] : dn;
                }, [Infinity, null]);
                if (_dist < distForMerge) click_node = _node;
                // console.log('click_node',click_node)

                if (tool === "columns") {
                    if (!plan.virtualColumn) {
                        plan.virtualColumn = new Column(point);

                        plan.virtualColumn.depth = columnParams.width;
                        plan.virtualColumn.width = columnParams.depth;
                    } else {
                        plan.virtualColumn.x = point.x;
                        plan.virtualColumn.y = point.y;
                    }

                    const cid = plan.columns.length;
                    const stickPoint = stickColumnToWall(
                        point,
                        plan.bWalls,
                        plan.virtualColumn,
                        cid,
                        zoom
                    );

                    if (stickPoint) {
                        const {x, y, angle, wallIndex, wallSide} = stickPoint;

                        plan.virtualColumn.x = x;
                        plan.virtualColumn.y = y;
                        plan.virtualColumn.angle = angle;

                        plan.virtualColumn.setParentWallID(wallIndex, wallSide);
                        plan.bWalls[wallIndex].setColumns(cid, wallSide);
                    }

                    plan.columns.push(plan.virtualColumn);
                    initColumnPoints(plan.virtualColumn);

                    plan.virtualColumn = new Column(point);
                    plan.virtualColumn.width = columnParams.width;
                    plan.virtualColumn.depth = columnParams.depth;

                    setUndo({ type: "plan" });
                    plan.setFloors('floor');
                    removeColumn();

                    draw(ctx, plan);
                    return;
                }

                if (plan.mode === 'walls' || plan.mode === 'ruler' || plan.mode === 'figures' || plan.mode === 'leader') {
                    if (plan.dragNode) {
                        const [_dist, _node] = plan.nodes.reduce((dn, n) => {
                            let _d;
                            if (plan.dragNode !== n)
                                _d = distNodeToNode(plan.dragNode, n);
                            else {
                                _d = Infinity; // Отсеиваем ноду, которую тянем
                            }
                            return _d < dn[0] ? [_d, n] : dn;
                        }, [Infinity, null]);
                        if (_dist < distForMerge) {
                            if (plan.virtualLink) {
                                plan.virtualNode.x = _node.x;
                                plan.virtualNode.y = _node.y;
                                plan.virtualLink.b = plan.dragNode = plan.virtualNode;
                            } else {
                                plan.dragNode.x = _node.x;
                                plan.dragNode.y = _node.y;
                            }
                        }
                        let flagLeng0 = false;
                        if (plan.virtualLink) {
                            if (plan.mode === 'ruler' || plan.mode === 'leader') {
                                plan.putRuller();
                                if (!checkMatchNode(plan.links[plan.links.length - 1].a, plan.links[plan.links.length - 1].b)) {
                                    _setActiveObject({
                                        point: plan.dragNode,
                                        object: plan.virtualLink
                                    })
                                }
                                else {
                                    plan.links.length = plan.links.length - 1;
                                }

                            } else if (plan.mode === 'walls' || plan.mode === 'figures') {
                                //* Тут проверяем что новый отрезок присоединен к стене и угол меньше 1грд - тогда удлиняем стену
                                let wall;
                                // for (let i = 0; i < plan.bWalls.length; i++) {
                                //     const commonPoint = getCommonPoint(plan.bWalls[i].mainLink, plan.virtualLink);
                                //     const angle = calcAngleDeg(plan.bWalls[i].mainLink, plan.virtualLink);
                                //     if (commonPoint && (angle < 1 || (angle > 179 && angle < 181))) {
                                //         plan.extendWall(plan.bWalls[i]);
                                //         wall = plan.bWalls[i];
                                //         break
                                //     }
                                // }

                                // if (!wall) {
                                wall = plan.putWall();
                                // }

                                plan.clearEmptyNodes();


                                if (checkMatchNode(plan.nodes[plan.nodes.length - 1], plan.nodes[plan.nodes.length - 2])) {
                                    plan.nodes.length = plan.nodes.length - 2;
                                    flagLeng0 = true;
                                    dispatch(projectState.setModal(''));
                                } else {
                                    _setActiveObject({
                                        point: plan.dragNode,
                                        object: wall
                                    })
                                }
                            }
                        }
                        if (plan.dragNode.isWall) {
                            plan.setFloors('floor');
                        } else if (plan.dragNode.isFigure) {
                            plan.setFloors('figures');
                        }

                        if (!flagLeng0) {
                            setUndo({ type: 'plan' });
                        }
                    }

                }
                else if (plan.mode === 'move' || plan.mode === 'view') {
                    if (activeObject && activeObject?.isColumn) {
                        setUndo({ type: 'plan' });
                    }

                    let clickColumn = null;
                    let clickColumnObject = null;
                    plan.columns.forEach((column) => {
                        if (checkHoverColumn(column, point)) {
                            for (let i = 0; i < column?.objects?.length; i++) {
                                const obj = column?.objects?.[i];
                                if (obj && polyPoint(obj.points, point.x, point.y)) {
                                    clickColumnObject = obj;
                                    clickColumnObject.column = column;
                                    clickColumnObject.obj = i;
                                    break;
                                }
                            }
                            clickColumn = column;
                        }
                    })
                    if (clickColumnObject) {
                        _setActiveObject({
                            point: pointWindow,
                            object: clickColumnObject
                        });
                    } else if (clickColumn && !clickColumnObject) {
                        _setActiveObject({
                            point: pointWindow,
                            object: clickColumn
                        });
                    } else if (click_node) { // Потом точки
                        plan.dragNode = click_node;
                        clickObject = {
                            type: 'node',
                            object: click_node,
                        };
                        _setActiveObject({
                            point,
                            object: clickObject
                        });
                        setUndo({ type: 'plan' });

                    } else if (plan.dragNode) {
                        if (plan.dragNode.isWall) {
                            plan.setFloors('floor');
                        }
                        else if (plan.dragNode.isFigure) {
                            plan.setFloors('figures');
                        }
                        setUndo({ type: 'plan' });

                    } else {

                        if (activeObject && activeObject.isModule && rotate) {
                            setUndo({
                                type: 'rotateModule', opt: {
                                    obj: activeObject,
                                    angle: activeObject.angle,
                                    prev: undoModulePrev.angel
                                }
                            });
                            dispatch(projectState.setModal(''));
                        } else if (activeObject && activeObject.isModule && moving) {
                            setUndo({
                                type: 'movingModule', opt: {
                                    obj: activeObject,
                                    position: { x: activeObject.position.x, y: activeObject.position.y },
                                    prev: { x: undoModulePrev.position.x, y: undoModulePrev.position.y }
                                }
                            });
                            dispatch(projectState.setModal(''));
                        }
                        let clickObjectModule = null;
                        if (filters.furniture) {
                            props.modules.forEach(m => {
                                if (m.isHover(point)) clickObjectModule = m;
                            });
                        }
                        const clickAngle = !clickObjectModule ? Object.entries(angles).find(([_, value]) => {
                            return Math.abs(pointWindow.x - value?.position?.x) < 20 && Math.abs(pointWindow.y - value?.position?.y) < 16
                        }) : null;

                        let isAngle = false;

                        if (clickObjectModule && !rotate) { // Верхний слой при нажатии - модули
                            _setActiveObject({
                                pointWindow,
                                object: clickObjectModule
                            });
                        } else if (clickAngle) {
                            const [key, value] = clickAngle;

                            let firstMainLink, secondMainLink;

                            plan.bWalls.forEach((wall) => {
                                if (wall.innerLink === value?.links?.[0]) firstMainLink = wall.mainLink;
                                if (wall.innerLink === value?.links?.[1]) secondMainLink = wall.mainLink;
                            })

                            if (firstMainLink && secondMainLink) {
                                const prevMainLink = prevActiveObject?.mainLink;

                                const firstFree = plan.isFreeLink(firstMainLink);
                                const secondFree = plan.isFreeLink(secondMainLink);

                                let freeLink = firstFree || secondFree;

                                if (prevMainLink && firstFree && secondFree && (firstFree === prevMainLink || secondFree === prevMainLink)) {
                                    freeLink = prevMainLink;
                                }

                                if (freeLink) {
                                    isAngle = true;

                                    angles.current = key;
                                    angles[key] = {
                                        ...angles[key],
                                        freeLink,
                                        links: [firstMainLink, secondMainLink],
                                    }
                                    onChange({
                                        object: angles[key],
                                        value: value.angle,
                                        position: value.position
                                    });
                                    console.log(angles.current)
                                }
                            }
                        }

                        if (!clickObjectModule && !isAngle) {
                            const linkByPoint = getLinkByPoint(pointWindow);
                            if (linkByPoint) { // Потом линии
                                _setActiveObject({
                                    point: pointWindow,
                                    object: linkByPoint
                                })
                            } else {
                                const wallByPoint = getWallByPoint(pointWindow);
                                let clickWallObject = null;
                                if (wallByPoint) { // Потом стены
                                    // click wall objects
                                    if (!wallByPoint.isArc) {
                                        for (let i = 0; i < wallByPoint.objects.length; i++) {
                                            const obj = wallByPoint.objects[i];
                                            if (polyPoint(obj.points, point.x, point.y)) {
                                                clickWallObject = obj;
                                                clickWallObject.wall = wallByPoint;
                                                clickWallObject.obj = i;
                                                _setActiveObject({
                                                    point: pointWindow,
                                                    object: clickWallObject
                                                });
                                                break;
                                            }
                                        }
                                    }

                                    if (!clickWallObject) {
                                        _setActiveObject({
                                            point: pointWindow,
                                            object: wallByPoint
                                        });
                                    }

                                    // console.log('wallByPoint',wallByPoint)

                                } else if (!columnSizing) {
                                    const cycleFigureByPoint = getCycleByPoint(pointWindow, 'figures');
                                    if (cycleFigureByPoint) { // Потом фигуры
                                        _setActiveObject({
                                            point: pointWindow,
                                            object: cycleFigureByPoint.cycle
                                        });
                                    } else {
                                        const cycleFloorByPoint = getCycleByPoint(pointWindow, 'floors');

                                        if (zoom > 0.045) {
                                            plan.cycles.forEach((cycle) => {
                                                const diagonalCenter = Convert.toPixel(cycle.diagonalCenter);
                                                if (!selectedText.diagonalCenter) {
                                                    selectedText.calculateBoundingBox(cycle, diagonalCenter, pointWindow);
                                                }
                                            })
                                        }

                                        if (selectedText.diagonalCenter) {
                                            _setActiveObject({
                                                point: pointWindow,
                                                object: selectedText
                                            });
                                            dispatch(projectState.setModal('info'));
                                        }

                                        if (cycleFloorByPoint && !selectedText.diagonalCenter) {
                                            selectedRoom.getPoints(cycleFloorByPoint.cycle);

                                            _setActiveObject({
                                                point: pointWindow,
                                                object: cycleFloorByPoint.cycle
                                            });

                                            plan.setCycleActive(cycleFloorByPoint.index);
                                        }
                                    }
                                }
                            }
                            angles.current = null;
                            clearField();
                        }
                    }
                }
            }

            if (
                activeObject === prevActiveObject
                && (cancelTouch ? true : !is_touch_device())
                && !columnSizing
                && !rotate
                && !isHoverColumn
                && !isContextMenu
                && !angles.current
            ) {
                if (activeObject?.isColumn || activeObject?.isModule) {
                    activeObject.showSizeButtons = false;
                }
                _setActiveObject(null);
                activeObject = null;
                plan.setCycleActive(-1);
            }

            if (isHoverColumn || columnSizing) {
                plan.setFloors('floor');
            }

            if (!scaling) {
                plan.setFloorMaterials(plan.cycles.filter((cycle) => {
                    if (plan.dragNode?.isControl && cycle._links.some((link) => link?.controlA)) return true;
                    return !cycle?.image;
                }));

                plan.virtualLink = null;
                plan.virtualNode = null;
                plan.dragNode = null;

                drag = false;
                moving = false;
                scaling = false;
                dragDistance = false;
                rotate = false;
                touchPrevEvent = null;
                clickMousePoint = null;
                startPosition = null;
                columnSizing = false;
                hoverObject = null;
                isHoverColumn = false;
                columnSizingPrev = 0;
                isContextMenu = false;

                plan.links = removingZeroLengthLinks(plan.links);
                plan.bWalls = removingZeroLengthWalls(plan.bWalls);
                plan.clearEmptyNodes();
                draw(ctx, plan);
            }
        }

        const handlerMove = (event) => {
            if (isWheelEvent) return;

            if (event.type === "touchmove") {
                event.preventDefault();
            }

            const distForMerge = Math.min(Math.max(3, 13 / zoom), 100);

            hoverObject = null;

            const touches = event.touches || event.changedTouches;
            if (scaling && touches?.length === 2) {
                distance = Math.round(Math.sqrt(
                    (touches[0].clientX - touches[1].clientX) *
                    (touches[0].clientX - touches[1].clientX) +
                    (touches[0].clientY - touches[1].clientY) *
                    (touches[0].clientY - touches[1].clientY)
                ) / 5) * 5;

                const scaleFactor = 0.005;
                if (lastDistance !== distance) {
                    const zoomChange = (distance - lastDistance) * scaleFactor;
                    zoom *= Math.exp(zoomChange);

                    if (zoom < plan.maxZoom) zoom = plan.maxZoom;
                    if (zoom > plan.minZoom) zoom = plan.minZoom;

                    plan.zoom = zoom;
                    lastDistance = distance;

                    if (zoom >= plan.maxZoom && zoom <= plan.minZoom) {
                        const mousePointAfterZoom = ConvertWithParams.toSM(
                            new Node((touches[0].clientX + touches[1].clientX) / 2, (touches[0].clientY + touches[1].clientY) / 2),
                            ctx,
                            zoom,
                            offsetX, offsetY
                        );

                        offsetX -= (mousePointAfterZoom.x - touchesZoom.x) * zoom;
                        offsetY -= (mousePointAfterZoom.y - touchesZoom.y) * zoom;

                        plan.offsetX = offsetX;
                        plan.offsetY = offsetY;
                    }
                }
                draw(ctx, plan);
                return;
            }

            let point;
            if (event.type === 'touchmove' || event.type === 'touchstart') {
                if (event.changedTouches.length < 1) return;
                point = Convert.toSM(new Node(event.changedTouches[0].clientX, event.changedTouches[0].clientY));
            } else {
                point = Convert.toSM(new Node(event.clientX, event.clientY));
            }

            if (tool === "columns") {
                if (!plan.virtualColumn) {
                    plan.virtualColumn = new Column(point);
                } else {
                    plan.virtualColumn.x = point.x;
                    plan.virtualColumn.y = point.y;
                }
                plan.virtualColumn.depth = columnParams.width;
                plan.virtualColumn.width = columnParams.depth;

                const cid = plan.columns.length;
                plan.virtualColumn.setParentWallID(-1);

                const stickPoint = stickColumnToWall(
                    point,
                    plan.bWalls,
                    plan.virtualColumn,
                    cid,
                    zoom
                );

                if (stickPoint) {
                    const { x, y, angle, wallIndex, wallSide } = stickPoint;

                    plan.virtualColumn.x = x;
                    plan.virtualColumn.y = y;
                    plan.virtualColumn.angle = angle;

                    plan.virtualColumn.setParentWallID(wallIndex, wallSide);
                    plan.bWalls[wallIndex].setColumns(cid, wallSide);
                }

                draw(ctx, plan);
                return;
            }

            if (plan.dragNode && (plan.mode === 'walls' || plan.mode === 'ruler' || plan.mode === 'figures' || plan.mode === 'leader' || plan.mode === 'move')) {
                let x = point.x, y = point.y;

                if (snap > 0) {
                    for (let i = 0; (plan.nodes.length - 1) > i; i++) {
                        if (plan.dragNode !== plan.nodes[i]) {
                            // const delta = distForMerge
                            const diffX = Math.abs(plan.nodes[i].x - point.x)
                            const diffY = Math.abs(plan.nodes[i].y - point.y)
                            if (diffX <= distForMerge && diffY < (canvas.clientHeight / (plan.zoom))) {
                                x = plan.nodes[i].x;
                            }
                            if (diffY <= distForMerge && diffX < (canvas.clientWidth / (plan.zoom))) {
                                y = plan.nodes[i].y;
                            }
                        }
                    }

                    plan.columns.forEach((column) => {
                        column.points.forEach((columnPoint) => {
                            const diffX = Math.abs(columnPoint.x - point.x)
                            const diffY = Math.abs(columnPoint.y - point.y)
                            if (diffX <= distForMerge && diffY < (canvas.clientHeight / (plan.zoom))) {
                                x = columnPoint.x;
                            }
                            if (diffY <= distForMerge && diffX < (canvas.clientWidth / (plan.zoom))) {
                                y = columnPoint.y;
                            }
                        })
                    });

                    x = Math.round(x / 5) * 5;
                    y = Math.round(y / 5) * 5;
                }

                //таскание точки тут
                plan.dragNode.x = x;
                plan.dragNode.y = y;
                if (plan.mode === 'move') {
                    if (currentWall && (currentWall.leftCols.length || currentWall.rightCols.length)) {
                        const changeColumnParams = (cid) => {
                            const col = plan.columns?.[cid];

                            if (col) {
                                const stickPoint = stickColumnToWall(
                                    { x: col.x, y: col.y },
                                    plan.bWalls.map((wall) => wall !== currentWall ? null : wall),
                                    col,
                                    cid,
                                    zoom,
                                    Infinity
                                );

                                if (stickPoint) {
                                    const { x, y, angle, wallIndex, wallSide } = stickPoint;

                                    col.x = x;
                                    col.y = y;
                                    col.angle = angle;

                                    col.setParentWallID(wallIndex, wallSide);
                                    plan.bWalls[wallIndex].setColumns(cid, wallSide);
                                } else {
                                    col.parentWallID = -1;
                                }
                            }
                        }
                        currentWall.leftCols.forEach(changeColumnParams);
                        currentWall.rightCols.forEach(changeColumnParams);
                    }

                    plan.moveDragPairNode();
                }

                // Ищем ближайшие ноды для привязки
                const [_dist, _node] = plan.nodes.reduce((dn, n) => {

                    let _d;
                    if (plan.dragNode !== n)
                        _d = distNodeToNode(plan.dragNode, n);
                    else
                        _d = Infinity; // Отсеиваем ноду, которую тянем
                    return _d < dn[0] ? [_d, n] : dn;
                }, [Infinity, null]);
                if (_dist < distForMerge) {
                    plan.dragNode.x = _node.x;
                    plan.dragNode.y = _node.y;
                    if (plan.mode === 'move') {

                        plan.moveDragPairNode();
                    }
                }

                if (plan.dragNode.isRuler) {
                    const stickPoint = stickPointToWall(plan.dragNode, plan.bWalls, zoom);
                    if (stickPoint) {
                        const dist = distNodeToNode(plan.dragNode, { x: stickPoint.x, y: stickPoint.y });
                        if (dist < distForMerge) {
                            plan.dragNode.x = stickPoint.x;
                            plan.dragNode.y = stickPoint.y;
                        }
                    }
                }

                if (plan.virtualLink) {
                    const wall = plan.bWalls.filter(w => w.mainLink === plan.virtualLink)[0];
                    if (wall) {
                        _setActiveObject({
                            point,
                            object: wall
                        })
                    }
                }

            } else if (plan.mode === 'move' || plan.mode === 'view') {
                // selectedRoom.move(plan, activeObject, point, pointWindow);
                if (rotate && activeObject?.showSizeButtons) {
                    if (
                        activeObject
                        && (activeObject.isModule || activeObject.isColumn)
                        && clickMousePoint
                    ) {
                        activeObject.lookTo(point, clickMousePoint, startAngle);
                        _setActiveObject({ point, object: activeObject });
                        draw(ctx, plan);
                        drag = false;
                        return;
                    }
                } else {
                    if (activeObject && activeObject.isModule && clickMousePoint) {
                        const vPos = clickMousePoint.clone().sub(point) //вектор перемещения
                        if (startPosition === null) {
                            startPosition = activeObject.position.clone();
                        }
                        activeObject.showSizeButtons = false;
                        activeObject.position = { x: startPosition.x - vPos.x, y: startPosition.y - vPos.y };
                        draw(ctx, plan);
                        drag = false;
                        return;
                    }
                }

                if (activeObject && activeObject.isColumn && columnSizing && activeObject?.showSizeButtons) {
                    const column = activeObject;

                    const isColumnHovered = checkHoverColumn(column, {
                        x: point.x,
                        y: point.y,
                    });

                    let objectPoint = new Vector2(column.x, column.y);

                    let angelFix, angelFix1;
                    if (
                        columnSizingDirection === "top" ||
                        columnSizingDirection === "bottom"
                    ) {
                        angelFix = 0;
                        angelFix1 = 90 * (Math.PI / 180);
                    } else if (
                        columnSizingDirection === "right" ||
                        columnSizingDirection === "left"
                    ) {
                        angelFix = 90 * (Math.PI / 180);
                        angelFix1 = 0;
                    }

                    const pointA = new Vector2(point.x, point.y);
                    const pointB = new Vector2(point.x, point.y);
                    const v = new Vector2(point.x, point.y)
                        .normalize()
                        .rotateAround(
                            new Vector2(0, 0),
                            -column.angle - new Vector2(point.x, point.y).angle() - angelFix
                        )
                        .setLength(100000);

                    pointA.x = pointA.x + v.x;
                    pointA.y = pointA.y + v.y;
                    pointB.x = pointB.x - v.x;
                    pointB.y = pointB.y - v.y;

                    const pointA1 = new Vector2(column.x, column.y);
                    const pointB1 = new Vector2(column.x, column.y);
                    const v2 = new Vector2(column.x, column.y)
                        .normalize()
                        .rotateAround(
                            new Vector2(0, 0),
                            -column.angle - new Vector2(column.x, column.y).angle() - angelFix1
                        )
                        .setLength(100000);

                    pointA1.x = pointA1.x + v2.x;
                    pointA1.y = pointA1.y + v2.y;
                    pointB1.x = pointB1.x - v2.x;
                    pointB1.y = pointB1.y - v2.y;

                    const crossPoint = getIntersection(
                        { x: pointA, y: pointB },
                        { x: pointA1, y: pointB1 }
                    );

                    let columnSizingCurrent = objectPoint.clone().sub(crossPoint).length();

                    let length = Math.round(columnSizingCurrent - columnSizingPrev);
                    const v3 = pointA1
                        .clone()
                        .sub(pointB1)
                        .setLength(length / 2);

                    const isNotMinHeight = column.depth + length >= columnParams.minDepth;
                    const isNotMinWidth = column.width + length >= columnParams.minWidth;

                    let sizeLine = getParallelEdgeLine3(column, columnSizingDirection);
                    const closestPoint = sizeLine.closestPointToPointParameter(
                        new Vector3(point.x, point.y, 0),
                        true
                    );

                    if (columnSizingPrev > 0 && closestPoint < 1) {
                        if (columnSizingDirection === "top") {
                            if (isNotMinHeight) {
                                column.depth = column.depth + length;
                                column.x = column.x + v3.x;
                                column.y = column.y + v3.y;
                            }
                        } else if (columnSizingDirection === "bottom") {
                            if (isNotMinHeight) {
                                column.depth = column.depth + length;
                                column.x = column.x - v3.x;
                                column.y = column.y - v3.y;
                            }
                        } else if (columnSizingDirection === "right") {
                            if (isNotMinWidth) {
                                column.width = column.width + length;
                                column.x = column.x + v3.x;
                                column.y = column.y + v3.y;
                            }
                        } else if (columnSizingDirection === "left") {
                            if (isNotMinWidth) {
                                column.width = column.width + length;
                                column.x = column.x - v3.x;
                                column.y = column.y - v3.y;
                            }
                        }
                    }
                    if (closestPoint < 1 && !isColumnHovered) {
                        objectPoint = new Vector2(column.x, column.y);
                        columnSizingCurrent = objectPoint.clone().sub(crossPoint).length();

                        columnSizingPrev = columnSizingCurrent;
                    }

                    _setActiveObject({ point, object: column });
                } else if (activeObject && activeObject.isColumn && isHoverColumn) {
                    activeObject.x += point.x - startPosition.x;
                    activeObject.y += point.y - startPosition.y;

                    startPosition = point;

                    activeObject.showSizeButtons = false;

                    const cid = plan.columns.findIndex((el) => el === activeObject);
                    activeObject.setParentWallID(-1);

                    const stickPoint = stickColumnToWall(
                        point,
                        plan.bWalls,
                        activeObject,
                        cid,
                        zoom
                    );

                    if (stickPoint) {
                        const { x, y, angle, wallIndex, wallSide } = stickPoint;

                        activeObject.x = x;
                        activeObject.y = y;
                        activeObject.angle = angle;
                        activeObject.isFixed = false;

                        activeObject.setParentWallID(wallIndex, wallSide);
                        plan.bWalls[wallIndex].setColumns(cid, wallSide);
                    }
                } else if (selectedRoom.ready2Move) {
                    const point3 = new Vector3(point.x, point.y, 0);
                    let distanceToWallAxis = Infinity;
                    let magnetizationNode;

                    plan.activeObject?.object?._links?.forEach(link => {
                        const mainWallLine = new Line3(
                            new Vector3(link.a.x, link.a.y, 0),
                            new Vector3(link.b.x, link.b.y, 0),
                        )
                        const target = new Vector3();
                        mainWallLine.closestPointToPoint(point3, true, target);
                        const distance = point3.distanceTo(target);
                        if (distanceToWallAxis > distance) {
                            distanceToWallAxis = point3.distanceTo(target);
                            magnetizationNode = new Node(target.x, target.y);
                        }
                    })

                    if (magnetizationNode) {
                        plan.magnetization.nodeOnWall = magnetizationNode;
                        plan.magnetization.nodeOnScreen = Convert.toPixel(magnetizationNode);

                        const closestWall = plan.getClosestWall(plan.bWalls, plan.magnetization.nodeOnWall, 20, activeObject.links);

                        // plan.magnetizationNode = pointWindow;
                        if (
                            event.type === "touchmove"
                            && event?.changedTouches
                            && touchPrevEvent?.changedTouches
                            && event.changedTouches.length > 0
                            && touchPrevEvent.changedTouches.length > 0
                        ) {
                            if (closestWall.point) {
                                let offset = { x: 0, y: 0 };
                                if (plan.prevClosestWallPoint !== undefined) {
                                    offset.x = closestWall.point.x - plan.prevClosestWallPoint.x;
                                    offset.y = closestWall.point.y - plan.prevClosestWallPoint.y;
                                } else {
                                    selectedRoom.move(plan, modules, activeObject, closestWall.offset.x, closestWall.offset.y);
                                }
                                selectedRoom.move(plan, modules, activeObject, offset.x, offset.y);
                                plan.prevClosestWallPoint = closestWall.point;
                                plan.magnetization.locked = true;

                            } else {
                                const touchOffset = {};
                                if (prevTouch === null) {
                                    touchOffset.x = event.changedTouches[0].clientX - touchPrevEvent.changedTouches[0].clientX;
                                    touchOffset.y = event.changedTouches[0].clientY - touchPrevEvent.changedTouches[0].clientY;

                                } else {
                                    touchOffset.x = event.changedTouches[0].clientX - prevTouch.clientX;
                                    touchOffset.y = event.changedTouches[0].clientY - prevTouch.clientY;
                                }
                                selectedRoom.move(plan, modules, activeObject, touchOffset.x / zoom, touchOffset.y / zoom);
                                prevTouch = event.changedTouches[0];
                                plan.prevClosestWallPoint = undefined;
                                plan.magnetization.locked = false;
                            }
                        } else {
                            if (closestWall.point) {
                                let offset = { x: 0, y: 0 };
                                if (plan.prevClosestWallPoint !== undefined) {
                                    offset.x = closestWall.point.x - plan.prevClosestWallPoint.x;
                                    offset.y = closestWall.point.y - plan.prevClosestWallPoint.y;
                                } else {
                                    selectedRoom.move(plan, modules, activeObject, closestWall.offset.x, closestWall.offset.y);
                                }
                                selectedRoom.move(plan, modules, activeObject, offset.x, offset.y);
                                plan.prevClosestWallPoint = closestWall.point;
                                plan.magnetization.locked = true;
                            } else {
                                selectedRoom.move(plan, modules, activeObject, event.movementX / zoom, event.movementY / zoom);
                                plan.prevClosestWallPoint = undefined;
                                plan.magnetization.locked = false;
                            }
                        }
                    }

                } else if (selectedText.diagonalCenter && selectedText.readyToMove) {
                    if (
                        event.type === "touchmove"
                        && event?.changedTouches
                        && touchPrevEvent?.changedTouches
                        && event.changedTouches.length > 0
                        && touchPrevEvent.changedTouches.length > 0
                    ) {
                        const touchOffset = {};
                        if (prevTouch === null) {
                            touchOffset.x = event.changedTouches[0].clientX - touchPrevEvent.changedTouches[0].clientX;
                            touchOffset.y = event.changedTouches[0].clientY - touchPrevEvent.changedTouches[0].clientY;

                        } else {
                            touchOffset.x = event.changedTouches[0].clientX - prevTouch.clientX;
                            touchOffset.y = event.changedTouches[0].clientY - prevTouch.clientY;
                        }
                        selectedText.move(touchOffset.x / zoom, touchOffset.y / zoom);
                        prevTouch = event.changedTouches[0];
                    } else {
                        selectedText.move(event.movementX / zoom, event.movementY / zoom);
                    }
                } else {
                    // ↓↓↓ тут по клику перемещаем камеру
                    if (event.type === 'touchmove') {

                        if (!scaling) {
                            if (
                                event?.changedTouches
                                && touchPrevEvent?.changedTouches
                                && event?.changedTouches?.length > 0
                                && touchPrevEvent?.changedTouches?.length > 0
                            ) {
                                offsetX -= (event.changedTouches[0].clientX - touchPrevEvent.changedTouches[0].clientX);
                                offsetY -= (event.changedTouches[0].clientY - touchPrevEvent.changedTouches[0].clientY);

                                if (!dragDistance) {
                                    dragDistance = Math.abs(event.changedTouches[0].clientX - touchPrevEvent.changedTouches[0].clientX) > 10
                                        || Math.abs(event.changedTouches[0].clientY - touchPrevEvent.changedTouches[0].clientY) > 10;
                                }

                                plan.offsetX = offsetX
                                plan.offsetY = offsetY
                                plan.canvasCenter = Convert.toSM(Convert.getCanvasCenterPixel())
                            }

                            touchPrevEvent = event;

                        }
                    } else if (drag) {
                        offsetX -= event.movementX;
                        offsetY -= event.movementY;

                        plan.offsetX = offsetX
                        plan.offsetY = offsetY
                        plan.canvasCenter = Convert.toSM(Convert.getCanvasCenterPixel())
                    }
                }
            }

            draw(ctx, plan);
        }

        const handlerOut = (event) => {
            if (event.type === "touchcancel")
                event.preventDefault();

            draw(ctx, plan);
        }

        const getWallByPoint = (pointWindow, onActive = false) => {
            let result = null;
            if (filters.walls) {
                plan.bWalls.map(wall => {
                    if (activeObject !== null
                        && activeObject.isWall
                        && activeObject.mainLink === wall.mainLink
                        && !onActive) {
                        // Стена, которая уже выделена - пропускаем, чтобы можно было выбрать стену "под ней"
                        // result = wall;
                    } else {
                        ctxFake.clearRect(0, 0, ctxFake.width, ctxFake.height);
                        ctxFake.fillStyle = colors.main.point;
                        ctxFake.strokeStyle = colors.main.point;
                        if (wall.isBezier && wall.outlineBezier) {
                            ctxFake.beginPath();

                            [...wall.inlineBezier].reverse().forEach((bezier, index) => {
                                const a = Convert.toPixel(bezier.points[3]);
                                const b = Convert.toPixel(bezier.points[2]);
                                const c = Convert.toPixel(bezier.points[1]);
                                const d = Convert.toPixel(bezier.points[0]);

                                if (index === 0) {
                                    ctxFake.moveTo(a.x, a.y);
                                } else {
                                    ctxFake.lineTo(a.x, a.y);
                                }

                                ctxFake.bezierCurveTo(b.x, b.y, c.x, c.y, d.x, d.y);
                            });

                            wall.outlineBezier.forEach((bezier) => {
                                const a = Convert.toPixel(bezier.points[0]);
                                const b = Convert.toPixel(bezier.points[1]);
                                const c = Convert.toPixel(bezier.points[2]);
                                const d = Convert.toPixel(bezier.points[3]);

                                ctxFake.lineTo(a.x, a.y);
                                ctxFake.bezierCurveTo(b.x, b.y, c.x, c.y, d.x, d.y);
                            })

                            ctxFake.closePath()
                            ctxFake.fill();
                        } else {
                            const a = Convert.toPixel(wall.nodes[2]);
                            const b = Convert.toPixel(wall.nodes[3]);
                            const c = Convert.toPixel(wall.nodes[5]);
                            const d = Convert.toPixel(wall.nodes[4]);

                            ctxFake.beginPath();
                            ctxFake.moveTo(a.x, a.y);
                            ctxFake.lineTo(b.x, b.y);
                            ctxFake.lineTo(c.x, c.y);
                            ctxFake.lineTo(d.x, d.y);
                            ctxFake.closePath();
                            ctxFake.fill();
                        }
                        function rgbToHex(r, g, b) {
                            if (r > 255 || g > 255 || b > 255)
                                throw "Invalid color component";
                            return ((r << 16) | (g << 8) | b).toString(16);
                        }
                        const pixel = ctxFake.getImageData(pointWindow.x * ratio, pointWindow.y * ratio, 1, 1).data
                        const hex = "#" + ("000000" + rgbToHex(pixel[0], pixel[1], pixel[2])).slice(-6);

                        if (hex === colors.main.point) {
                            result = wall;
                        }
                    }

                });
            }
            return result;
        }
        const getLinkByPoint = (pointWindow) => {
            let result = false;
            plan.links.map(link => {
                if ((filters.ruler && (link.isRuler || link.isLeader)) || (filters.floors && link.isFigure)) {
                    if (activeObject !== null
                        && (activeObject.isRuler || activeObject.isFigure || activeObject?.isLeader)
                        && activeObject === link) {
                        // Линк, который уже выделен - пропускаем, чтобы можно было выбрать линк "под ним"
                    } else {
                        const linkDepth = 100;
                        ctxFake.clearRect(0, 0, ctxFake.width, ctxFake.height);
                        ctxFake.lineWidth = (linkDepth * 2) * zoom
                        ctxFake.fillStyle = colors.main.point;
                        ctxFake.strokeStyle = colors.main.point;
                        if (link.isArc && link.arcRadius1) {
                            const pivotPoint = Convert.toPixel(link.a);
                            const arcRadius2 = Math.ceil((link.arcRadius + linkDepth / 2) * zoom);

                            ctxFake.lineWidth = (linkDepth + 300) * zoom;
                            ctxFake.beginPath();
                            ctxFake.arc(pivotPoint.x, pivotPoint.y, arcRadius2, link.arcRadius1.angle(), link.arcRadius2.angle());
                            ctxFake.stroke();
                        } else {
                            const a = Convert.toPixel(link.a);
                            const b = Convert.toPixel(link.b);

                            ctxFake.beginPath();
                            if (link?.isLeader) {
                                const leaderLength = 1000 * zoom;
                                ctxFake.moveTo(a.x, a.y);
                                ctxFake.lineTo(b.x, b.y);
                                ctxFake.lineTo(b.x + leaderLength, b.y);
                            } else {
                                ctxFake.moveTo(a.x, a.y);
                                ctxFake.lineTo(b.x, b.y);
                            }
                            ctxFake.closePath();
                            ctxFake.stroke();
                        }
                        function rgbToHex(r, g, b) {
                            if (r > 255 || g > 255 || b > 255)
                                throw "Invalid color component";
                            return ((r << 16) | (g << 8) | b).toString(16);
                        }
                        const pixel = ctxFake.getImageData(pointWindow.x * ratio, pointWindow.y * ratio, 1, 1).data
                        const hex = "#" + ("000000" + rgbToHex(pixel[0], pixel[1], pixel[2])).slice(-6);

                        if (hex === colors.main.point) {
                            result = link;
                        }
                    }
                }

            });
            return result;
        }
        const getCycleByPoint = (pointWindow, type) => {
            let result = false;
            if (filters.floors) {
                plan.cycles.map((cycle, index) => {

                    if ((type === 'figures' && cycle.isFigure) || (type === 'floors' && cycle.isFloor)) {
                        if (activeObject !== null
                            && activeObject.isCycle
                            && activeObject === cycle) {
                            // Цикл, который уже выделен - пропускаем, чтобы можно было выбрать цикл "под ним"
                        } else {

                            ctxFake.clearRect(0, 0, ctxFake.width, ctxFake.height);
                            ctxFake.lineWidth = 1;
                            ctxFake.fillStyle = colors.main.point;
                            ctxFake.strokeStyle = colors.main.point;
                            ctxFake.beginPath();
                            cycle.points.forEach((node, index) => {
                                const point = Convert.toPixel(new Node(node.x, node.y))
                                if (index === 0) {
                                    ctxFake.moveTo(point.x, point.y);
                                }

                                const wall = plan.bWalls.filter((w) => w.innerLink.a === node || w.innerLink.b === node)[0];
                                if (wall?.isBezier) {
                                    const a = Convert.toPixel(wall.nodes[0]);
                                    const b = Convert.toPixel(wall.nodes[1]);
                                    const control_1A = Convert.toPixel(wall.bezierControlPoint_1A);
                                    const control_1B = Convert.toPixel(wall.bezierControlPoint_1B);

                                    if (b.x === point.x && b.y === point.y) {
                                        ctxFake.lineTo(b.x, b.y);
                                        ctxFake.bezierCurveTo(control_1B.x, control_1B.y, control_1A.x, control_1A.y, a.x, a.y);
                                    } else {
                                        ctxFake.lineTo(a.x, a.y);
                                        ctxFake.bezierCurveTo(control_1A.x, control_1A.y, control_1B.x, control_1B.y, b.x, b.y);
                                    }
                                } else {
                                    ctxFake.lineTo(point.x, point.y);
                                }
                            });
                            ctxFake.closePath();
                            ctxFake.stroke();
                            ctxFake.fill();

                            const pixel = ctxFake.getImageData(pointWindow.x * ratio, pointWindow.y * ratio, 1, 1).data
                            const hex = "#" + ("000000" + rgbToHex(pixel[0], pixel[1], pixel[2])).slice(-6);

                            if (hex === colors.main.point) {
                                result = { cycle, index };
                            }
                        }
                    }

                });
            }
            return result;
        }
        ////////////////////////////////////////////////////////////////////////////////
        const handlerContext = (e) => {
            let point = null;

            if (is_touch_device()) {
                point = new Node(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
            } else {
                point = new Node(e.clientX, e.clientY);
                e.preventDefault();
            }
            if (plan.mode === 'view') return false;

            const isWall = getWallByPoint(point, true);
            let isColumn = false;
            let isModule = props.modules.some((m) => m.isHover(Convert.toSM(point)));

            if (plan?.columns?.length) {
                isColumn = plan.columns.some((column) => checkHoverColumn(column, Convert.toSM(point)));
            }

            if (isWall || isColumn || isModule) {
                setAnchorPoint({ x: point.x + 5, y: point.y + 5 });
                setShowMenu(true);
                isContextMenu = true;
            }

            return false;
        };
        ////////////////////////////////////////////////////////////////////////////////
        const handlerRedraw = (e) => {
            e.preventDefault();
            draw(ctx, plan);
            return false;
        };
        const handlerRedrawSimple = (e) => {
            dispatch(projectState.addPreloader());
            dispatch(projectState.decPreloader());
            e.preventDefault();
            draw(ctx, plan);
            return false;
        };

        const handlerPlanAnimation = (e) => {
            e.preventDefault();

            const { offsetX: oX, offsetY: oY, zoom: z } = plan.getCommonPlanPosition();

            const startOffsetX = plan.offsetX;
            const startOffsetY = plan.offsetY;
            const startZoom = plan.zoom;

            const duration = 1000;
            let startTime;

            function animate(time) {
                if (!startTime) startTime = time;
                const timeElapsed = time - startTime;
                const progress = Math.min(timeElapsed / duration, 1);

                offsetX = startOffsetX + (oX - startOffsetX) * progress;
                offsetY = startOffsetY + (oY - startOffsetY) * progress;
                zoom = startZoom + (z - startZoom) * progress;

                draw(ctx, plan);

                if (progress < 1) {
                    requestAnimationFrame(animate);
                }
            }

            plan.offsetX = oX;
            plan.offsetY = oY;
            plan.zoom = z;

            requestAnimationFrame(animate);
        }

        const handlerCenterModule = (e) => {
            e.preventDefault();

            offsetX = (canvas.width / 2) - ((canvas.width / 2) / ratio);
            offsetY = (canvas.height / 2) - ((canvas.height / 2) / ratio);

            offsetX = Convert.toPixel({ x: e.obj._position.x, y: e.obj._position.y }).x + 100 / ratio;
            offsetY = Convert.toPixel({ x: e.obj._position.x, y: e.obj._position.y }).y;

            _setActiveObject({
                point: Convert.toSM(new Node(e.obj._position.x, e.obj._position.y)),
                object: e.obj
            });
            zoom = .1;

            draw(ctx, plan);
            return false;
        };
        const handlerSelectObject = (e) => {
            e.preventDefault();

            const activeObject = e.obj;

            activeObject.wall = e.wall;
            activeObject.column = e.column;

            activeObject.obj = e.obj.id;

            _setActiveObject({
                point: {},
                object: activeObject
            });

            draw(ctx, plan);
            return false;
        };
        ////////////////////////////////////////////////////////////////////////////////
        const handlerUnselect = (e) => {
            if (activeObject?.showSizeButtons) {
                activeObject.showSizeButtons = false;
            }

            e.preventDefault();
            hoverObject = null
            activeObject = null
            _setActiveObject(null)

            drag = false;
            rotate = false;
            clickObject = null;
            plan.virtualNode = null
            plan.virtualLink = null

            draw(ctx, plan);
            return false;
        };
        ////////////////////////////////////////////////////////////////////////////////
        const handlerReselect = (e) => {
            e.preventDefault();
            const __activeObject = activeObject
            // console.log('__activeObject',__activeObject)
            _setActiveObject(null)
            _setActiveObject({ point: {}, object: __activeObject })

            draw(ctx, plan);
            return false;
        };
        ////////////////////////////////////////////////////////////////////////////////

        let wheelTimeout = null
        const handlerWheel = (e) => {
            e.preventDefault();
            clearTimeout(wheelTimeout);
            isWheelEvent = true;

            const scaleSpeed = 0.0005;
            const zoomChange = Math.exp(-e.deltaY * scaleSpeed);
            const newZoom = zoom * zoomChange;

            if (newZoom > plan.minZoom || newZoom < plan.maxZoom) return;

            zoom = newZoom;

            const mousePointBeforeZoom = ConvertWithParams.toSM(
                new Node(e.clientX, e.clientY),
                ctx,
                zoom / zoomChange,
                offsetX, offsetY
            );
            const mousePointAfterZoom = ConvertWithParams.toSM(
                new Node(e.clientX, e.clientY),
                ctx,
                zoom,
                offsetX, offsetY
            );

            offsetX -= (mousePointAfterZoom.x - mousePointBeforeZoom.x) * zoom;
            offsetY -= (mousePointAfterZoom.y - mousePointBeforeZoom.y) * zoom;

            plan.offsetX = offsetX;
            plan.offsetY = offsetY;

            plan.zoom = zoom;
            plan.canvasCenter = Convert.toSM(Convert.getCanvasCenterPixel());

            draw(ctx, plan);

            wheelTimeout = setTimeout(() => {
                isWheelEvent = false;
            }, 100);
        };
        ////////////////////////////////////////////////////////////////////////////////
        const handlerResize = () => {
            // const {width, height} = canvas.getBoundingClientRect();
            const width = window.innerWidth;
            const height = window.innerHeight;

            let { devicePixelRatio: ratio = 1 } = window
            ratio = ratio < 1 ? 2 - ratio : ratio;

            if (is_touch_device()) {
                offsetY -= (canvas.height - height * ratio) / 2;
            } else {
                offsetX = (canvas.width / ratio) / 2;
                offsetY = (canvas.height / ratio) / 2;
            }

            const isResize = canvas.width !== width || canvas.height !== height;

            if (isResize) {
                canvas.width = ctx.width = width * ratio
                canvas.height = ctx.height = height * ratio
                ctx.scale(ratio, ratio)

                canvasFake.width = ctxFake.width = width * ratio
                canvasFake.height = ctxFake.height = height * ratio
                ctxFake.scale(ratio, ratio)
            }

            plan.offsetX = offsetX
            plan.offsetY = offsetY
            plan.canvasCenter = Convert.toSM(Convert.getCanvasCenterPixel())

            draw(ctx, plan);
        }
        ////////////////////////////////////////////////////////////////////////////////
        const _setActiveObject = (obj) => {
            // setActiveObject(null);
            if (obj) {
                activeObject = obj.object;
            }
            if (!obj || !obj.object.isFloor)
                plan.setCycleActive(-1);



            plan.setActiveObject(obj);
            setActiveObject(obj);

            //console.log('plan.activeObject',plan.activeObject)
            //console.log('_activeObject', _activeObject)
            //console.log('activeObject', activeObject)
            //console.log('obj',obj)
        }
        ////////////////////////////////////////////////////////////////////////////////
        const draw = (ctx, plan) => {
            infos = []
            ctx.fillStyle = '#EEEEEE';
            ctx.strokeStyle = '#000000';
            ctx.font = '12px Arial';
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.clearRect(0, 0, ctx.width, ctx.height);

            drawGrid(ctx, offsetX, offsetY, zoom, ref, ImageBGData, refCanvasImageBG);

            if (filters.floors) {
                const sortFloors = [];
                plan.cycles.forEach((cycle) => {
                    if (cycle.isFigure) {
                        sortFloors.push(cycle);
                    } else {
                        sortFloors.unshift(cycle);
                    }
                })
                sortFloors.forEach(cycle => {
                    drawFloor(cycle);
                });
                plan.links.forEach(link => {
                    if (link?.isFigure) {
                        drawFigure(link);
                    }
                })
                if (plan.virtualLink && plan.virtualLink.isFigure) {
                    drawFigure(plan.virtualLink);
                }
                plan.nodes.map((n) => {
                    if (n.isFigure) {
                        drawNode(n);
                    }
                });
                if (plan.virtualNode && plan.virtualNode.isFigure) {
                    drawNode(plan.virtualNode);
                }
            }

            if (filters.walls) {
                const commonParams = { ctx, zoom, offsetX, offsetY, activeObject, plan, filters, level };

                if (plan.virtualColumn && plan.virtualColumn.isColumn) {
                    drawColumn({
                        ...commonParams,
                        column: plan.virtualColumn,
                        walls: plan.bWalls,
                    });
                }

                plan.columns.forEach((column) => {
                    drawColumn({
                        ...commonParams,
                        column,
                        walls: plan.bWalls,
                    });
                    drawWallObjects(ctx, column, filters, activeObject, infos, zoom, offsetX, offsetY, showEstimated);
                });

                if (activeObject !== null && activeObject.isWall && !activeObject.isLink) {
                    drawWall(activeObject);
                }

                if (plan.virtualLink && plan.virtualLink.isWall) {
                    drawLink(plan.virtualLink);
                }

                plan.bWalls.map((wall) => {
                    drawWall(wall);
                });

                plan.bWalls.map((wall) => {
                    drawWallObjects(ctx, wall, filters, activeObject, infos, zoom, offsetX, offsetY, showEstimated);
                })

                plan.nodes.map((n) => {
                    if (n.isWall || n.isControl) {
                        drawNode(n);
                    }
                });
                if (plan.virtualNode && plan.virtualNode.isWall) {
                    drawNode(plan.virtualNode);
                }
            }

            if (filters.ruler) {
                if (plan.virtualLink && plan.virtualLink.isLeader) {
                    drawLeader(plan.virtualLink);
                }

                plan.links.forEach((link) => {
                    if (link?.isLeader) {
                        drawLeader(link);
                    }
                })

                plan.nodes.map((n) => {
                    if (
                        n.isLeader
                        && activeObject !== null
                        && activeObject?.isLeader
                        && (activeObject?.a === n || activeObject?.b === n)
                    ) {
                        drawNode(n);
                    }
                });

                if (filters.walls) {
                    plan.bWalls.map(w => {
                        if (!w.leftCols.length && !w.rightCols.length) {
                            drawSize(w.innerLink, w.mainLink.lrBuild);
                        }
                    });
                    if (plan.virtualLink && plan.virtualLink.isWall)
                        drawSize(plan.virtualLink, plan.virtualLink.lrBuild);

                    if (activeObject !== null && activeObject.isWall && !activeObject.isLink) {
                        drawSize(activeObject.innerLink, activeObject.mainLink.lrBuild);
                    }
                }
                if (filters.floors) {
                    plan.links.map(link => {
                        if (link.isFigure)
                            drawSize(link);
                    })
                    if (plan.virtualLink && plan.virtualLink.isFigure)
                        drawSize(plan.virtualLink);
                }

                drawRulers();
            }

            if (filters.furniture) {
                props.modules.map(m => drawModule(m));
            }

            infos.map(point => {
                drawInfos(point)
            });

            if (plan.tempNode) {// точка для тестов
                ctx.beginPath();
                let r = 30 * zoom;
                ctx.fillStyle = 'red';
                ctx.arc(plan.tempNode.x, plan.tempNode.y, r, 0, 2 * Math.PI);
                ctx.fill();
                ctx.stroke();
                ctx.beginPath();
                ctx.fillStyle = 'green';
                r = 15 * zoom;
                ctx.arc(plan.tempNode.x, plan.tempNode.y, r, 0, 2 * Math.PI);
                ctx.fill();
                ctx.stroke();
            }
            if (plan.magnetization.nodeOnScreen) {// точка примагничивания помещения
                drawLock(ctx, plan.magnetization.nodeOnScreen, plan.magnetization.locked);
            }
        }
        ///////////////////////////////////////////////////////////////////////////////
        const drawFloor = (cycle) => {
            const { text, main, estimated, selected, selectedStroke } = colors;

            const fillColor = cycle?.rgbColor
                ? "#" + ("000000" + rgbToHex(cycle.rgb.r, cycle.rgb.g, cycle.rgb.b)).slice(-6)
                : main.floor;

            ctx.font = '12px Arial';
            ctx.fillStyle = fillColor;
            ctx.strokeStyle = main.floorStroke;

            if (showEstimated && cycle.estimate && cycle.estimate.length === 0) {
                ctx.fillStyle = estimated.floor;
                ctx.strokeStyle = estimated.floorStroke;
            }

            const isSelected = activeObject !== null && activeObject.isCycle && activeObject === cycle;

            if (isSelected) {
                ctx.fillStyle = selected;
                ctx.strokeStyle = selectedStroke;
            }

            if (cycle?.image && cycle?.texRect && !isSelected && !cycle?.rgbColor && filters.materials) {
                const point = Convert.toPixel(new Node(cycle.texRect.x, cycle.texRect.y));
                ctx.drawImage(cycle.image, point.x, point.y, cycle.texRect.w * zoom, cycle.texRect.h * zoom)
            } else {
                cycle.points.forEach((node, index) => {
                    const point = Convert.toPixel(new Node(node.x, node.y))
                    if (index === 0) {
                        ctx.beginPath();
                        ctx.moveTo(point.x, point.y);
                    }

                    let wall = null;
                    let isReverse = false;
                    if (index < cycle.points.length - 1) {
                        const _a = node;
                        const _b = cycle.points[index + 1];
                        wall = plan.bWalls.find((w) => {
                            if (w.innerLink.a === _a && w.innerLink.b === _b) {
                                return true;
                            } else if (w.innerLink.a === _b && w.innerLink.b === _a) {
                                isReverse = true;
                                return true;
                            }
                            return false;
                        });
                    }

                    if (wall && wall.isArc && wall.arcRadius1) {

                        const pivotPoint = Convert.toPixel(wall.nodes[4]);
                        const arcRadius1 = Math.ceil(wall.arcRadius * zoom);
                        // const arcRadius2 = Math.ceil((wall.arcRadius+wall.mainLink.depth/2)*zoom);

                        // ctx.lineWidth = wall.mainLink.depth*zoom;
                        // ctx.beginPath();
                        if (wall.mainLink.lrBuild === 'left')
                            ctx.arc(pivotPoint.x, pivotPoint.y, arcRadius1, wall.arcRadius1.angle(), wall.arcRadius2.angle());
                        else
                            ctx.arc(pivotPoint.x, pivotPoint.y, arcRadius1, wall.arcRadius2.angle(), wall.arcRadius1.angle());
                        // ctx.stroke();

                    } else if (wall && wall.isBezier) {
                        const a = Convert.toPixel(wall.nodes[0]);
                        const b = Convert.toPixel(wall.nodes[1]);
                        const control_1A = Convert.toPixel(wall.bezierControlPoint_1A);
                        const control_1B = Convert.toPixel(wall.bezierControlPoint_1B);

                        if (isReverse) {
                            ctx.lineTo(b.x, b.y);
                            ctx.bezierCurveTo(control_1B.x, control_1B.y, control_1A.x, control_1A.y, a.x, a.y);
                        } else {
                            ctx.lineTo(a.x, a.y);
                            ctx.bezierCurveTo(control_1A.x, control_1A.y, control_1B.x, control_1B.y, b.x, b.y);
                        }
                    } else {
                        ctx.lineTo(point.x, point.y);
                        ctx.stroke();
                    }

                });

                ctx.closePath();
                ctx.stroke();
                ctx.fill();
            }

            if (cycle?.diagonalCenter) {
                ctx.fillStyle = text;
                const diagonalCenterPixel = Convert.toPixel(cycle.diagonalCenter);
                ctx.fillText(cycle.squareText, diagonalCenterPixel.x, diagonalCenterPixel.y);

                ctx.font = '14px Arial';
                ctx.fillText(cycle.objTitle, diagonalCenterPixel.x, diagonalCenterPixel.y - 20);

                if (cycle.objComment !== '' || cycle.objImages.length > 0) {

                    infos.push({
                        type: 'ruler',
                        x: diagonalCenterPixel.x,
                        y: diagonalCenterPixel.y - 400 * zoom,
                    })
                }
            }

            if (selectedText.diagonalCenter && selectedText.diagonalCenter === cycle.diagonalCenter) {
                const diagonalCenter = Convert.toPixel(selectedText.diagonalCenter);
                const position = selectedText.getPosition(cycle, diagonalCenter);
                ctx.save();
                ctx.lineWidth = 2;
                ctx.strokeStyle = colors.selected;
                ctx.strokeRect(position.x, position.y, selectedText.width, selectedText.height + (cycle?.objTitle?.length ? selectedText.height : 0));
                ctx.restore();
            }
        }
        /////////////////////////////////////////////////////////////////////////////////////
        const drawRulers = () => {
            plan.links.map(link => {
                if (link.isRuler) {
                    drawRuler(link);
                }
            })
            if (plan.virtualLink && plan.virtualLink.isRuler) {
                drawRuler(plan.virtualLink)
            }
        }
        const drawRuler = (ruler) => {
            const length = (Math.round((new Vector2(ruler.a.x, ruler.a.y))
                .distanceTo(new Vector2(ruler.b.x, ruler.b.y)))).toString()

            const ln = Math.abs(ruler.a.clone().sub(ruler.b).length())
            const tw = ctx.measureText(length).width + 8

            // if(ln*zoom>tw+30) {}

            ctx.font = '10px Arial';

            const _a = Convert.toPixel(ruler.a)
            const _b = Convert.toPixel(ruler.b)

            const v = _b.clone().sub(_a); //вектор стены
            const c = _b.clone().add(_a).multiplyScalar(0.5); //центр в рихелях
            const a = v.angle(); //угол поворота в rad относительно ------------> (x)filll
            const len = v.length();

            if (ruler.objTitle !== '' || ruler.objComment !== '' || ruler.objImages.length > 0) {

                const __v = v.clone().setLength(ln / 2 - (50 * zoom))
                const __point = { x: __v.x + ruler.a.x, y: __v.y + ruler.a.y }
                const point = Convert.toPixel(__point)

                infos.push({
                    type: 'ruler',
                    x: point.x,
                    y: point.y,
                })
            }

            ctx.strokeStyle = ctx.fillStyle = '#FFB800'
            ctx.lineWidth = 1
            if (
                (hoverObject && hoverObject.a === ruler.a && hoverObject.b === ruler.b) ||
                (activeObject && activeObject.a === ruler.a && activeObject.b === ruler.b)
            ) {
                ctx.strokeStyle = ctx.fillStyle = '#59DA28'
            }

            let halfH = -20
            ctx.translate(c.x, c.y);
            ctx.rotate(a);
            ctx.beginPath();
            ctx.moveTo(-len / 2, -halfH - 17);
            ctx.lineTo(-len / 2, -halfH - 23);

            ctx.moveTo(len / 2, -halfH - 17);
            ctx.lineTo(len / 2, -halfH - 23);

            ctx.moveTo(-len / 2, -halfH - 20);
            ctx.lineTo(len / 2, -halfH - 20);
            ctx.stroke();

            if (ruler.showCircle) {
                ctx.beginPath();
                ctx.arc(-len / 2, -halfH - 20, len, 0, 2 * Math.PI);
                ctx.stroke();
            }

            halfH = -10

            ctx.translate(0, -8 - halfH);
            ctx.fillRect(-tw / 2, -8 - 1, tw, 16);
            ctx.strokeRect(-tw / 2, -8 - 1, tw, 16);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
            ctx.fillStyle = '#FFFFFF';
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2)
                ctx.fillText(length, 0, 2);
            else
                ctx.fillText(length, 0, 0);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
            ctx.translate(0, 8 + halfH);

            ctx.rotate(-a);
            ctx.translate(-c.x, -c.y);
        }

        const drawLink = (link) => {
            const _a = Convert.toPixel(link.a);
            const _b = Convert.toPixel(link.b);

            ctx.lineWidth = 1;
            ctx.fillStyle = '#000000';
            ctx.strokeStyle = '#000000';

            if (
                (hoverObject && hoverObject.a === link.a && hoverObject.b === link.b) ||
                (activeObject && activeObject.a === link.a && activeObject.b === link.b)
            ) {
                ctx.strokeStyle = ctx.fillStyle = '#59DA28'
            }

            ctx.beginPath();
            ctx.moveTo(_a.x, _a.y);
            ctx.lineTo(_b.x, _b.y);
            ctx.closePath();
            ctx.stroke();
            ctx.fill();
        }

        const patternCanvas = document.createElement("canvas");
        const patternContext = patternCanvas.getContext("2d");

        const drawWall = (wall) => {
            const { main, estimated, selectedStroke, selected } = colors;

            patternCanvas.width = Math.max(50 * zoom, 1);
            patternCanvas.height = Math.max(50 * zoom, 1);

            patternContext.fillStyle = main.patternBG;
            patternContext.fillRect(0, 0, patternCanvas.width, patternCanvas.height);
            patternContext.strokeStyle = main.hatch;

            if (showEstimated && wall.estimate && wall.estimate.length === 0) {
                patternContext.fillStyle = estimated.patternBG
            }

            ctx.lineWidth = 1;

            switch (wall.planMaterial) {
                case 'beigeFill': {
                    ctx.fillStyle = main.wall;
                    ctx.strokeStyle = main.wall;


                    if (showEstimated && wall.estimate && wall.estimate.length === 0) {
                        ctx.fillStyle = estimated.beigeFill;
                        ctx.strokeStyle = estimated.beigeFill;
                    }

                    break;
                }
                case 'greyFill': {
                    ctx.fillStyle = main.greyFill;
                    ctx.strokeStyle = main.greyFill;

                    if (showEstimated && wall.estimate && wall.estimate.length === 0) {
                        ctx.fillStyle = estimated.greyFill;
                        ctx.strokeStyle = estimated.greyFill;
                    }

                    break;
                }
                case 'hatchDraw': {
                    patternContext.moveTo(0, 0);
                    patternContext.lineTo(patternCanvas.width, patternCanvas.height);
                    patternContext.stroke();

                    const pattern = patternContext.createPattern(patternCanvas, "repeat");
                    const matrix = new DOMMatrix([1, 0, 0, 1, 0, 0]);

                    pattern.setTransform(matrix.translateSelf(-offsetX, -offsetY, 0));

                    ctx.fillStyle = pattern;

                    break;
                }
                case 'pointsDraw': {
                    patternContext.arc(patternCanvas.width / 2, patternCanvas.height / 2, 5 * zoom, 0, 2 * Math.PI);
                    patternContext.stroke();

                    const pattern = patternContext.createPattern(patternCanvas, "repeat");
                    const matrix = new DOMMatrix([1, 0, 0, 1, 0, 0]);

                    pattern.setTransform(matrix.translateSelf(-offsetX, -offsetY, 0));

                    ctx.fillStyle = pattern;

                    break;
                }
                default: {
                    ctx.fillStyle = main.wall;
                    ctx.strokeStyle = main.wallStroke;

                    if (showEstimated && wall.estimate && wall.estimate.length === 0) {
                        ctx.fillStyle = estimated.wall;
                        ctx.strokeStyle = estimated.wallStroke;
                    }

                    break;
                }
            }


            if (activeObject !== null
                && activeObject.isWall
                && activeObject.mainLink === wall.mainLink) {
                ctx.fillStyle = selected;
                ctx.strokeStyle = selectedStroke;
            }

            if (wall.isBezier) {
                const a = Convert.toPixel(wall.nodes[0]);
                const b = Convert.toPixel(wall.nodes[1]);
                const c = Convert.toPixel(wall.nodes[2]);
                const d = Convert.toPixel(wall.nodes[3]);
                const e = Convert.toPixel(wall.nodes[4]);
                const f = Convert.toPixel(wall.nodes[5]);

                const control_1A = Convert.toPixel(wall.bezierControlPoint_1A);
                const control_1B = Convert.toPixel(wall.bezierControlPoint_1B);

                const offsetBezierStart = Convert.toPixel(wall.outlineBezier[0].points[0]);
                const offsetBezierEnd = Convert.toPixel(wall.outlineBezier[wall.outlineBezier.length - 1].points[3]);

                const inlineBezierStart = Convert.toPixel(wall.inlineBezier[0].points[0]);
                const inlineBezierEnd = Convert.toPixel(wall.inlineBezier[wall.inlineBezier.length - 1].points[3]);

                ctx.beginPath();

                // "Закрытие" стен кривой
                ctx.moveTo(a.x, a.y);
                ctx.lineTo(c.x, c.y);
                ctx.lineTo(offsetBezierStart.x, offsetBezierStart.y);
                ctx.lineTo(a.x, a.y);
                ctx.fill();

                ctx.moveTo(b.x, b.y);
                ctx.lineTo(d.x, d.y);
                ctx.lineTo(offsetBezierEnd.x, offsetBezierEnd.y);
                ctx.lineTo(b.x, b.y);
                ctx.fill();

                ctx.moveTo(a.x, a.y);
                ctx.lineTo(e.x, e.y);
                ctx.lineTo(inlineBezierStart.x, inlineBezierStart.y);
                ctx.lineTo(a.x, a.y);
                ctx.fill();

                ctx.moveTo(b.x, b.y);
                ctx.lineTo(f.x, f.y);
                ctx.lineTo(inlineBezierEnd.x, inlineBezierEnd.y);
                ctx.lineTo(b.x, b.y);
                ctx.fill();

                // Толщина стены. Задаётся параллельным переносом основной кривой
                ctx.moveTo(a.x, a.y);

                wall.outlineBezier.forEach((bezier, index) => {
                    const bezier_1A = Convert.toPixel(bezier.points[0]);
                    const bezier_2B = Convert.toPixel(bezier.points[1]);
                    const bezier_3C = Convert.toPixel(bezier.points[2]);
                    const bezier_4D = Convert.toPixel(bezier.points[3]);

                    ctx.lineTo(bezier_1A.x, bezier_1A.y);
                    ctx.bezierCurveTo(bezier_2B.x, bezier_2B.y, bezier_3C.x, bezier_3C.y, bezier_4D.x, bezier_4D.y);
                    ctx.stroke();
                });

                [...wall.inlineBezier].reverse().forEach((bezier, index) => {
                    const bezier_1A = Convert.toPixel(bezier.points[3]);
                    const bezier_2B = Convert.toPixel(bezier.points[2]);
                    const bezier_3C = Convert.toPixel(bezier.points[1]);
                    const bezier_4D = Convert.toPixel(bezier.points[0]);

                    ctx.lineTo(bezier_1A.x, bezier_1A.y);
                    ctx.bezierCurveTo(bezier_2B.x, bezier_2B.y, bezier_3C.x, bezier_3C.y, bezier_4D.x, bezier_4D.y);
                    ctx.stroke();
                })
                ctx.fill();
                ctx.closePath();

                // Манипуляторы для изменения кривой
                ctx.lineWidth = 1;
                ctx.moveTo(a.x, a.y);
                ctx.lineTo(control_1A.x, control_1A.y);
                ctx.moveTo(b.x, b.y);
                ctx.lineTo(control_1B.x, control_1B.y);
                ctx.stroke();

                wall.mainLink.controlA.isControl = true;
                wall.mainLink.controlB.isControl = true;
            } else {
                const a = Convert.toPixel(wall.nodes[4]);
                const b = Convert.toPixel(wall.nodes[5]);
                const c = Convert.toPixel(wall.nodes[0]);
                const d = Convert.toPixel(wall.nodes[1]);
                const e = Convert.toPixel(wall.nodes[2]);
                const f = Convert.toPixel(wall.nodes[3]);

                if (wall.mainLink?.controlA) {
                    wall.mainLink.controlA.isControl = false;
                }
                if (wall.mainLink?.controlB) {
                    wall.mainLink.controlB.isControl = false;
                }

                ctx.beginPath();
                ctx.moveTo(a.x, a.y);
                ctx.lineTo(b.x, b.y);
                ctx.lineTo(d.x, d.y);
                ctx.lineTo(f.x, f.y);
                ctx.lineTo(e.x, e.y);
                ctx.lineTo(c.x, c.y);
                ctx.lineTo(a.x, a.y);
                ctx.closePath();
                ctx.stroke();
                ctx.fill();
            }

            if (wall.objTitle !== '' || wall.objComment !== '' || wall.objImages.length > 0) {
                const _a = Convert.toPixel(wall.parallelLink.a);
                const _b = Convert.toPixel(wall.parallelLink.b);
                const point = _b.clone().add(_a).multiplyScalar(0.5);

                infos.push({
                    type: 'wall',
                    x: point.x,
                    y: point.y,
                });
            }
        }

        const drawLeader = (leader) => {
            ctx.lineWidth = 1
            ctx.fillStyle = '#000000';
            ctx.strokeStyle = '#000000';

            if (
                activeObject !== null
                && activeObject.isLeader
                && activeObject === leader
            ) {
                ctx.fillStyle = '#59DA28'
                ctx.strokeStyle = '#3ea516'
            }

            const a = Convert.toPixel(leader.a);
            const b = Convert.toPixel(leader.b);

            ctx.beginPath();
            ctx.moveTo(a.x, a.y);
            ctx.lineTo(b.x, b.y);

            const angle = Math.atan2(b.y - a.y, b.x - a.x);
            const headLength = 100 * zoom;

            ctx.moveTo(a.x, a.y);
            ctx.lineTo(a.x + headLength * Math.cos(angle - Math.PI / 12), a.y + headLength * Math.sin(angle - Math.PI / 12));
            ctx.lineTo(a.x + headLength * Math.cos(angle + Math.PI / 12), a.y + headLength * Math.sin(angle + Math.PI / 12));
            ctx.lineTo(a.x, a.y);
            ctx.fill();

            ctx.stroke();

            ctx.lineWidth = 2;
            const lineLength = 1000 * zoom;
            ctx.beginPath();
            ctx.moveTo(b.x, b.y);
            ctx.lineTo(b.x + lineLength, b.y);
            ctx.stroke();

            if (leader?.leaderText) {
                const textSize = 70 * zoom;
                const textX = b.x + lineLength / 2;
                const textY = b.y - textSize;

                function drawTextAboveLine(ctx, text, x, y, maxWidth) {
                    const words = text.split(' ');
                    let line = '';
                    const lineHeight = textSize;

                    let lines = [];

                    for (let i = 0; i < words.length; i++) {
                        const testLine = line + words[i] + ' ';
                        const metrics = ctx.measureText(testLine);
                        const testWidth = metrics.width;
                        if (testWidth > maxWidth && i > 0) {
                            lines.push(line);
                            line = words[i] + ' ';
                        } else {
                            line = testLine;
                        }
                    }
                    lines.push(line);

                    for (let i = lines.length - 1; i >= 0; i--) {
                        ctx.fillText(lines[i], x, y);
                        y -= lineHeight;
                    }
                }

                ctx.font = `${textSize}px Arial`;
                ctx.textAlign = "center";
                drawTextAboveLine(ctx, leader.leaderText, textX, textY, lineLength);
            }

            ctx.lineWidth = 1;
        }

        const drawFigure = (link) => {

            ctx.lineWidth = 1
            ctx.fillStyle = '#000000';
            ctx.strokeStyle = '#000000';

            if (activeObject !== null
                && activeObject.isFigure
                && activeObject === link) {
                ctx.fillStyle = '#59DA28'
                ctx.strokeStyle = '#3ea516'
            }

            if (link.isArc && link.arcRadius1) {

                /*
                // drawNode(wall.nodes[4],'pivotPoint');

                const pivotPoint = Convert.toPixel(wall.nodes[4]);
                // const arcRadius1 = Math.ceil(wall.arcRadius*zoom);
                const arcRadius2 = Math.ceil((wall.arcRadius+wall.mainLink.depth/2)*zoom);

                ctx.lineWidth = wall.mainLink.depth*zoom;
                ctx.beginPath();
                if (wall.mainLink.lrBuild==='left')
                    ctx.arc(pivotPoint.x, pivotPoint.y, arcRadius2, wall.arcRadius1.angle(), wall.arcRadius2.angle());
                else
                    ctx.arc(pivotPoint.x, pivotPoint.y, arcRadius2, wall.arcRadius2.angle(), wall.arcRadius1.angle());
                ctx.stroke();
                 */

            } else {
                const a = Convert.toPixel(link.a);
                const b = Convert.toPixel(link.b);

                ctx.beginPath();
                ctx.setLineDash([5]);
                ctx.moveTo(a.x, a.y);
                ctx.lineTo(b.x, b.y);
                ctx.stroke();
            }
            ctx.setLineDash([]);

        }

        /////////////////////////////////////////////////////////////////////////////////////
        const drawSize = (link, position) => {
            const wall = plan.bWalls.filter(w => w.innerLink === link)[0];
            const wallLength = link.length;
            const sortWallHoles = (a, b, link) => {
                if (link.a.x < link.b.x) {
                    return a.objB.x - b.objB.x
                } else if (link.a.x === link.b.x) {
                    return link.a.y < link.b.y ? a.objB.y - b.objB.y : b.objB.y - a.objB.y;
                } else {
                    return b.objB.x - a.objB.x;
                }
            }
            // Проверка на арку (потом надо переписать)
            const wallHoles = wall && !wall.isArc && !wall.isBezier ?
                [...wall.objects]
                    .sort((a, b) => sortWallHoles(a, b, link))
                    .filter(obj => obj.isHole || obj.isWindow || obj.isDoor) :
                [];

            const wallSizes = wallHoles.reduce((sizes, currHole, currentIndex) => {
                const prevHole = wallHoles[currentIndex - 1];

                // Размеры стены слева от проёма
                if (currentIndex === 0) {
                    sizes.push({ a: link.a, b: currHole.objA, width: currHole.len1 });
                } else {
                    sizes.push({ a: prevHole.objB, b: currHole.objA, width: currHole.len1 - prevHole.len1 - prevHole.width });
                }

                // Размеры текущего проема
                sizes.push({ a: currHole.objA, b: currHole.objB, width: currHole.width });

                // Размеры стены справа крайнего проёма
                if (currentIndex === wallHoles.length - 1) {
                    sizes.push({ a: currHole.objB, b: link.b, width: wallLength - currHole.len1 - currHole.width });
                }

                return sizes;
            }, []);

            if (wallHoles.length === 0) wallSizes[0] = { a: link.a, b: link.b, width: link.length };

            wallSizes.forEach((hole) => {
                ctx.lineWidth = 1
                ctx.font = '10px Arial';
                ctx.strokeStyle = '#FFB800';
                ctx.fillStyle = '#FFB800';

                if (activeObject !== null && activeObject.isWall && !activeObject.isLink && activeObject.innerLink === link) {
                    ctx.strokeStyle = '#59DA28';
                    ctx.fillStyle = '#59DA28';
                }

                let length = 0, lengthText = '';

                if (wall && wall.isArc && wall.arcRadius1) {
                    length = Math.abs(wall.arcLenght.toFixed(2));
                    lengthText = '∩ ' + length;
                } else if (wall && wall.isBezier && wall.bezierControlPoint_1A && wall.bezierControlPoint_1B) {
                    const distance = hole.width;
                    length = wall.inlineBezier.reduce((acc, cur) => {
                        acc += lengthBezierCurve(
                            cur.points[0].x, cur.points[0].y,
                            cur.points[1].x, cur.points[1].y,
                            cur.points[2].x, cur.points[2].y,
                            cur.points[3].x, cur.points[3].y,
                        );
                        return acc;
                    }, 0);
                    lengthText = distance + ', ∩ ' + length;
                } else {
                    length = hole.width;
                    lengthText = hole.width;
                }

                const tw = ctx.measureText(lengthText).width + 8;

                if (length * zoom > tw + 10 && position === 'right') {
                    const _a = Convert.toPixel(hole.a);
                    const _b = Convert.toPixel(hole.b);
                    const v = _b.clone().sub(_a); //вектор стены
                    const c = _b.clone().add(_a).multiplyScalar(0.5); //центр в рихелях
                    const a = v.angle(); //угол поворота в rad относительно ------------> (x)filll
                    const len = v.length();
                    let halfH = -12;
                    ctx.translate(c.x, c.y);
                    ctx.rotate(a);
                    ctx.beginPath();
                    ctx.moveTo(-len / 2, -halfH - 17);
                    ctx.lineTo(-len / 2, -halfH - 23);

                    ctx.moveTo(len / 2, -halfH - 17);
                    ctx.lineTo(len / 2, -halfH - 23);

                    ctx.moveTo(-len / 2, -halfH - 20);
                    ctx.lineTo(len / 2, -halfH - 20);
                    ctx.stroke();

                    halfH = 0;

                    ctx.translate(0, -8 - halfH);
                    ctx.fillRect(-tw / 2, -8 - 1, tw, 16);
                    ctx.strokeRect(-tw / 2, -8 - 1, tw, 16);
                    if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
                    ctx.fillStyle = '#FFFFFF';
                    ctx.fillText(lengthText, 0, 0);
                    if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
                    ctx.translate(0, 8 + halfH);

                    ctx.rotate(-a);
                    ctx.translate(-c.x, -c.y);
                } else if (length * zoom > tw + 10) {
                    const _a = Convert.toPixel(hole.a);
                    const _b = Convert.toPixel(hole.b);
                    const v = _b.clone().sub(_a); //вектор стены
                    const c = _b.clone().add(_a).multiplyScalar(0.5); //центр в рихелях
                    const a = v.angle(); //угол поворота в rad относительно ------------> (x)filll
                    const len = v.length();
                    let halfH = -28;
                    ctx.translate(c.x, c.y);
                    ctx.rotate(a);
                    ctx.beginPath();
                    ctx.moveTo(-len / 2, -halfH - 17);
                    ctx.lineTo(-len / 2, -halfH - 23);

                    ctx.moveTo(len / 2, -halfH - 17);
                    ctx.lineTo(len / 2, -halfH - 23);

                    ctx.moveTo(-len / 2, -halfH - 20);
                    ctx.lineTo(len / 2, -halfH - 20);
                    ctx.stroke();

                    halfH = 0;

                    ctx.translate(0, 8 + halfH);
                    ctx.fillRect(-tw / 2, -8 + 1, tw, 16);
                    ctx.strokeRect(-tw / 2, -8 + 1, tw, 16);
                    if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
                    ctx.fillStyle = '#FFFFFF';
                    ctx.fillText(lengthText, 0, 0);
                    if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
                    ctx.translate(0, -8 - halfH);

                    ctx.rotate(-a);
                    ctx.translate(-c.x, -c.y);
                }
            })
        }
        //////////////////////////////////////////////////////////////////////////////
        const drawInfos = (point) => {

            ctx.fillStyle = '#28b6da90';
            ctx.strokeStyle = '#ffffff90';
            ctx.font = '14px Arial';

            ctx.lineWidth = 1;
            ctx.beginPath();
            const r = 80 * zoom;

            ctx.arc(point.x, point.y, r, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();

            ctx.fillStyle = '#fff';
            ctx.fillText('i', point.x, point.y);
        };
        const drawNode = (n) => {
            ctx.fillStyle = '#ffffff'
            ctx.strokeStyle = '#A1A1A1'

            if (n.isLocked) {
                ctx.fillStyle = '#ffffff70'
                ctx.strokeStyle = '#A1A1A170'
            }
            if (activeObject && n === activeObject.object) {
                ctx.fillStyle = '#59DA28'
                ctx.strokeStyle = '#3ea516'
            }

            ctx.fillStyle = '#F00'
            ctx.strokeStyle = '#F00'

            const _n = Convert.toPixel(n);
            ctx.lineWidth = 1.5
            ctx.beginPath()
            const r = Math.min(13, 8 / zoom);
            if (n.isControl) {
                ctx.fillStyle = '#daa228'
                ctx.strokeStyle = '#a57316'
            }

            ctx.arc(_n.x, _n.y, r * zoom, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();

            if ((n.isFigure || n.isWall) && filters.angle) {
                drawAngles(n);
            }
        }
        const drawAngles = (n) => {
            const links = [];
            let node = n;
            let mainNode = n;
            let skipRender = false;

            if (n.isWall) {
                plan.bWalls.forEach((w) => {
                    if (w.mainLink.a.x === n.x && w.mainLink.a.y === n.y) {
                        node = w.innerLink.a;
                        links.push(w.innerLink);
                    } else if (w.mainLink.b.x === n.x && w.mainLink.b.y === n.y) {
                        node = w.innerLink.b;
                        links.push(w.innerLink);
                    }
                });
            }
            if (n.isFigure) {
                plan.links.forEach((l) => {
                    if ((l.a.x === n.x && l.a.y === n.y) || (l.b.x === n.x && l.b.y === n.y)) {
                        links.push(l);
                    }
                });
            }

            if (links.length >= 2) {
                const _n = Convert.toPixel(node);

                links.sort((a, b) => {
                    let v1;
                    if (a.a.x === node.x && a.a.y === node.y) {
                        v1 = a.a.clone().sub(a.b).negate().normalize().setLength(50 * zoom);
                    } else if (a.b.x === node.x && a.b.y === node.y) {
                        v1 = a.b.clone().sub(a.a).negate().normalize().setLength(50 * zoom);
                    }
                    let v2;
                    if (b.a.x === node.x && b.a.y === node.y) {
                        v2 = b.a.clone().sub(b.b).negate().normalize().setLength(50 * zoom);
                    } else if (b.b.x === node.x && b.b.y === node.y) {
                        v2 = b.b.clone().sub(b.a).negate().normalize().setLength(50 * zoom);
                    }

                    if (!v1 || !v2) {
                        skipRender = true;
                        return true;
                    }

                    return v2.angle() - v1.angle();
                });

                if (skipRender) return;

                let prevLinks = links[links.length - 1];

                links.forEach((link) => {
                    let _b1r, _a1, v1
                    if (prevLinks.a.x === node.x && prevLinks.a.y === node.y) {
                        v1 = prevLinks.a.clone().sub(prevLinks.b).negate().normalize().setLength(50 * zoom)
                        _a1 = Convert.toPixel(prevLinks.a)
                    } else if (prevLinks.b.x === node.x && prevLinks.b.y === node.y) {
                        v1 = prevLinks.b.clone().sub(prevLinks.a).negate().normalize().setLength(50 * zoom)
                        _a1 = Convert.toPixel(prevLinks.b)
                    }
                    _b1r = { x: v1.x + _a1.x, y: v1.y + _a1.y }

                    let _b2r, _a2, v2
                    if (link.a.x === node.x && link.a.y === node.y) {
                        v2 = link.a.clone().sub(link.b).negate().normalize().setLength(50 * zoom)
                        _a2 = Convert.toPixel(link.a)
                    } else if (link.b.x === node.x && link.b.y === node.y) {
                        v2 = link.b.clone().sub(link.a).negate().normalize().setLength(50 * zoom)
                        _a2 = Convert.toPixel(link.b)
                    }
                    _b2r = { x: v2.x + _a2.x, y: v2.y + _a2.y }

                    let _b4r, _b3r, v3, v4
                    v3 = (new Node(_b1r.x, _b1r.y)).sub((new Node(_b2r.x, _b2r.y))).multiplyScalar(.5)
                    _b3r = { x: v3.x + _b2r.x, y: v3.y + _b2r.y }

                    const angleA = v1.angle()
                    const angleB = v2.angle()

                    let angle;

                    if (angleA > angleB) {
                        angle = parseFloat(((angleA - angleB) * (180 / Math.PI)).toFixed(1));
                    } else {
                        angle = parseFloat(((2 * Math.PI - (angleB - angleA)) * (180 / Math.PI)).toFixed(1));
                    }

                    const arcR = Math.min(100 * zoom, 100 / zoom);
                    const textR = Math.min(150 * zoom, 150 / zoom);

                    if (angle >= 180) {
                        v4 = (new Node(_n.x, _n.y)).sub((new Node(_b3r.x, _b3r.y))).setLength(textR);
                    } else {
                        v4 = (new Node(_n.x, _n.y)).sub((new Node(_b3r.x, _b3r.y))).setLength(textR).negate();
                    }

                    if (angle >= 180) {
                        prevLinks = link
                        return
                    }

                    _b4r = { x: v4.x + _n.x, y: v4.y + _n.y }

                    ctx.fillStyle = '#000000';
                    ctx.font = '10px Arial';
                    ctx.fillText(angle + '°', _b4r.x, _b4r.y);

                    ctx.strokeStyle = '#FFB800'
                    ctx.lineWidth = 1
                    ctx.beginPath()
                    ctx.arc(_n.x, _n.y, arcR, v2.angle(), v1.angle());
                    ctx.stroke();

                    if (angles?.current === `${node.x},${node.y}`) {
                        onChange({ position: _b4r });
                    }

                    angles[`${node.x},${node.y}`] = {
                        ...(angles[`${node.x},${node.y}`] ? angles[`${node.x},${node.y}`] : {}),
                        angle,
                        position: _b4r,
                        links: [link, prevLinks],
                    };

                    prevLinks = link
                })
            }
        }
        ////////////////////////////////////////////////////////////////////////////
        const drawModule = (module) => {

            const pos = Convert.toPixel(new Vector2(module.position.x, module.position.y));


            ctx.translate(pos.x, pos.y);
            ctx.rotate(-module.angle);
            // ctx.scale(zoom,zoom);
            const moduleImage = (module === hoverObject) ? module.shema.hover : module.shema.normal

            const imageW = (moduleImage.width / 2 * zoom) * 10
            const imageH = (moduleImage.height / 2 * zoom) * 10
            let moduleX, moduleY
            // moduleX = ((-module.size.pivot.x/ratio*zoom) + (module.size.width/2/ratio*zoom) - imageW/2)
            // moduleY = ((-module.size.pivot.y/ratio*zoom) + (module.size.height/2/ratio*zoom) - imageH/2)
            moduleX = (-module.size.pivot.x) * zoom + (module.size.width / 2 * zoom) - imageW / 2
            moduleY = (-module.size.pivot.y) * zoom + (module.size.height / 2 * zoom) - imageH / 2
            // if (module._optionStatus)
            //     moduleY = ((-module.size.pivot.y) + (module.size.height/2) - imageH/2) - (41)

            if (module.objTitle !== '' || module.objComment !== '' || module.objImages.length > 0) {
                infos.push({
                    type: 'module',
                    x: pos.x,
                    y: pos.y - 20 * zoom,
                })
            }

            //Тень
            ctx.beginPath();
            ctx.strokeStyle = '#eeeeee50';
            ctx.fillStyle = '#eeeeee50';
            ctx.lineWidth = 50 * zoom;
            ctx.moveTo((-module.size.pivot.x) * zoom, (-module.size.pivot.y) * zoom);
            ctx.lineTo((module.size.width - module.size.pivot.x) * zoom, (-module.size.pivot.y) * zoom);
            ctx.lineTo((module.size.width - module.size.pivot.x) * zoom, (module.size.height - module.size.pivot.y) * zoom);
            ctx.lineTo((-module.size.pivot.x) * zoom, (module.size.height - module.size.pivot.y) * zoom);
            ctx.closePath();
            ctx.stroke();
            ctx.fill();
            ctx.lineWidth = 1;

            if (window.debug) {
                ctx.fillStyle = '#67889720'
                ctx.fillRect(moduleX, moduleY, imageW, imageH)
            }
            ctx.drawImage( //рисуется обекъек и цвет
                moduleImage, moduleX, moduleY, imageW, imageH,
            )

            if (showEstimated && module.estimate.length === 0) {
                ctx.beginPath();
                ctx.strokeStyle = colors.estimated.module;
                ctx.fillStyle = colors.estimated.module;
                ctx.lineWidth = 50 * zoom;
                ctx.moveTo((-module.size.pivot.x) * zoom, (-module.size.pivot.y) * zoom);
                ctx.lineTo((module.size.width - module.size.pivot.x) * zoom, (-module.size.pivot.y) * zoom);
                ctx.lineTo((module.size.width - module.size.pivot.x) * zoom, (module.size.height - module.size.pivot.y) * zoom);
                ctx.lineTo((-module.size.pivot.x) * zoom, (module.size.height - module.size.pivot.y) * zoom);
                ctx.closePath();
                ctx.stroke();
                ctx.fill();
                ctx.lineWidth = 1;
            }

            // console.log('ratio',ratio)
            // console.log('drawModule():module',module)
            // console.log('moduleImage.width, moduleImage.height',moduleImage.width, moduleImage.height)

            if (activeObject === module) {
                // ctx.scale(1/zoom,1/zoom);
                //периметр
                ctx.beginPath();
                ctx.strokeStyle = '#555555';
                ctx.fillStyle = '#555555'
                ctx.moveTo((-module.size.pivot.x) * zoom, (-module.size.pivot.y) * zoom);
                ctx.lineTo((module.size.width - module.size.pivot.x) * zoom, (-module.size.pivot.y) * zoom);
                ctx.lineTo((module.size.width - module.size.pivot.x) * zoom, (module.size.height - module.size.pivot.y) * zoom);
                ctx.lineTo((-module.size.pivot.x) * zoom, (module.size.height - module.size.pivot.y) * zoom);
                ctx.closePath();
                ctx.stroke();

                ctx.strokeStyle = ctx.fillStyle = '#59DA2880'
                ctx.lineWidth = 160 * zoom
                ctx.beginPath();
                ctx.moveTo((-module.size.pivot.x) * zoom - (80 * zoom), (-module.size.pivot.y) * zoom - (80 * zoom));
                ctx.lineTo((module.size.width - module.size.pivot.x) * zoom + (80 * zoom), (-module.size.pivot.y) * zoom - (80 * zoom));
                ctx.lineTo((module.size.width - module.size.pivot.x) * zoom + (80 * zoom), (module.size.height - module.size.pivot.y) * zoom + (80 * zoom));
                ctx.lineTo((-module.size.pivot.x) * zoom - (80 * zoom), (module.size.height - module.size.pivot.y) * zoom + (80 * zoom));
                ctx.closePath();
                ctx.stroke();
                ctx.lineWidth = 1

                // Pivot
                if (module?.showSizeButtons) {
                    const r = Math.min(12, 56 / zoom);
                    ctx.beginPath();
                    ctx.fillStyle = '#222222'
                    ctx.strokeStyle = '#ffffff';
                    ctx.lineWidth = 1;
                    ctx.arc(
                        (module.size.pivot.x - module.size.width - 10) * zoom - r / 2,
                        (module.size.pivot.y - module.size.height - 10) * zoom - r / 2,
                        r, 0, Math.PI * 2
                    );
                    ctx.fill();
                    ctx.stroke();

                    if (plan.icons.rotate) {
                        ctx.drawImage(
                            plan.icons.rotate,
                            (module.size.pivot.x - module.size.width - 10) * zoom - r,
                            (module.size.pivot.y - module.size.height - 10) * zoom - r,
                            r, r
                        );
                    }
                }

                //дистанции
                let d; //дистанция
                let origin = new Vector2(); // источник луча
                let direction = new Vector2(); // направление луча
                const zeroVector = new Vector2(0, 0);
                let w = module.size.width;
                let h = module.size.height;
                let pivot = module.size.pivot;
                let mod = module.model;
                let angle = module.angle;

                const drawRay = (directionSide) => {

                    d = Infinity;
                    let ctxTranslateX;
                    let ctxTranslateY;
                    let ctxRotate;
                    switch (directionSide) {
                        case 'up': {
                            origin.x = 0;
                            origin.y = -pivot.y;
                            direction.x = 0;
                            direction.y = -1;
                            ctx.strokeStyle = "#7e5c52";
                            ctx.fillStyle = "#7e5c52";
                            break
                        }
                        case 'bottom': {
                            origin.x = 0;
                            origin.y = h - pivot.y;
                            direction.x = 0;
                            direction.y = 1;
                            ctx.strokeStyle = "#e5c100";
                            ctx.fillStyle = "#e5c100";
                            break
                        }
                        case 'left': {
                            origin.x = -pivot.x;
                            origin.y = 0;
                            direction.x = -1;
                            direction.y = 0;
                            ctx.strokeStyle = "#F56600";
                            ctx.fillStyle = "#F56600";
                            break
                        }
                        case 'right': {
                            origin.x = w - pivot.x;
                            origin.y = 0;
                            direction.x = 1;
                            direction.y = 0;
                            ctx.strokeStyle = "#6082B6";
                            ctx.fillStyle = "#6082B6";
                            break
                        }
                        default: { break }
                    }

                    origin.rotateAround(zeroVector, -angle);
                    origin.add(new Vector2(module.position.x, module.position.y));
                    direction.rotateAround(zeroVector, -angle);

                    const checkDotEntersRoom = (point, cycles) => { //проверяем что точка входи в выбраную комнату
                        const x = point.x;
                        const y = point.y;

                        let inside = false;
                        for (let i = 0, j = cycles._points.length - 1; i < cycles._points.length; j = i++) {
                            let xi = cycles._points[i].x, yi = cycles._points[i].y;
                            let xj = cycles._points[j].x, yj = cycles._points[j].y;
                            let intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                            if (intersect) {
                                inside = !inside;

                            }
                        }
                        return inside;
                    }

                    for (let c = 0; plan.cycles.length > c; c++) {
                        if (checkDotEntersRoom(origin, plan.cycles[c])) {

                            for (let i = 0; plan.cycles[c]._links.length > i; i++) {

                                for (let j = 0; plan.bWalls.length > j; j++) {

                                    if (checkMatchNode(plan.cycles[c]._links[i].a, plan.bWalls[j].mainLink.a)
                                        && checkMatchNode(plan.cycles[c]._links[i].b, plan.bWalls[j].mainLink.b)) {
                                        const d2w = distanceRayToWall(origin, direction, plan.bWalls[j]);
                                        if (d2w < d) {
                                            d = d2w;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    props.modules.forEach(m => {
                        if (m !== module) {
                            const _d = distanceRayToModule(origin, direction, m);
                            if (_d < d) d = _d;
                        }
                    });

                    switch (directionSide) {
                        case 'up': {
                            ctxTranslateX = 0;
                            ctxTranslateY = (-d / 2 - pivot.y);
                            ctxRotate = (-Math.PI / 2);
                            break
                        }
                        case 'bottom': {
                            ctxTranslateX = 0;
                            ctxTranslateY = (d / 2 + h - pivot.y);
                            ctxRotate = (-Math.PI / 2);
                            break
                        }
                        case 'left': {
                            ctxTranslateX = (-d / 2 - pivot.x);
                            ctxTranslateY = 0;
                            ctxRotate = 0;
                            break
                        }
                        case 'right': {
                            ctxTranslateX = (d / 2 + w - pivot.x);
                            ctxTranslateY = 0;
                            ctxRotate = 0;
                            break
                        }
                        default: { break }
                    }

                    if (d !== Infinity && d > 0) {
                        d = Math.round(d);
                        const tw = ctx.measureText(d.toString()).width + 8;
                        ctx.translate(ctxTranslateX * zoom, ctxTranslateY * zoom);
                        ctx.rotate(ctxRotate);
                        ctx.beginPath();
                        ctx.moveTo(-d * zoom / 2, 0);
                        ctx.lineTo(d * zoom / 2, 0);
                        ctx.stroke();
                        ctx.fillRect(-tw / 2, -8, tw, 16);
                        ctx.strokeStyle = "#FFFFFF";
                        ctx.fillStyle = "#FFFFFF";
                        ctx.fillText(d, 0, 0);
                        ctx.rotate(-ctxRotate);
                        ctx.translate(-ctxTranslateX * zoom, -ctxTranslateY * zoom);
                        locationInRooms[directionSide] = d;
                    }

                }
                const locationInRooms = { up: null, right: null, bottom: null, left: null };

                drawRay('up');
                drawRay('right');
                drawRay('bottom');
                drawRay('left');

                // dispatch(projectState.setLocationInRooms(locationInRooms));
                module.locationInRooms = locationInRooms;

            }
            // else ctx.scale(1/zoom,1/zoom);
            ctx.rotate(module.angle);
            ctx.translate(-pos.x, -pos.y);
        }

        const removeColumn = () => {
            if (tool === "columns") {
                const cid = plan.columns.length;
                plan.bWalls.forEach((wall) => {
                    wall.removeColumns(cid);
                });
                plan.virtualColumn = null;
                setMoveTool();
            }
        }

        //////////////////////////////////////////////////////////////////////////////
        const distanceRayToModule = (origin, direction, m) => {

            const p1 = m.position.clone().add(new Vector2(-m.size.pivot.x, -m.size.pivot.y)).rotateAround(m.position,-m.angle);
            const p2 = m.position.clone().add(new Vector2(m.size.width - m.size.pivot.x, -m.size.pivot.y)).rotateAround(m.position,-m.angle);
            const p3 = m.position.clone().add(new Vector2(m.size.width - m.size.pivot.x, m.size.height - m.size.pivot.y)).rotateAround(m.position,-m.angle);
            const p4 = m.position.clone().add(new Vector2(-m.size.pivot.x, m.size.height - m.size.pivot.y)).rotateAround(m.position,-m.angle);
            let d = Infinity;

            const dist = (origin, direction, a, b) => {
                const a1 = -direction.y;
                const b1 = direction.x;
                const c1 = origin.x * (origin.y + direction.y) - (origin.x + direction.x) * origin.y;

                const a2 = a.y - b.y;
                const b2 = b.x - a.x;
                const c2 = a.x * b.y - b.x * a.y;

                const det = (a, b, c, d) => a * d - b * c;

                const zn = det(a1, b1, a2, b2)

                if (Math.abs(zn) < 0.0000001) return Infinity;
                else {
                    const v = new Vector2(
                        - det(c1, b1, c2, b2) / zn,
                        - det(a1, c1, a2, c2) / zn
                    );
                    //проверяем сторону луча
                    if (v.clone().sub(origin).dot(direction) < 0) return Infinity;
                    //проверяем что точка на отрезке
                    if (a.x < b.x && (v.x < a.x || v.x > b.x)) return Infinity;
                    if (a.x > b.x && (v.x > a.x || v.x < b.x)) return Infinity;
                    if (a.x === b.x && a.y < b.y && (v.y < a.y || v.y > b.y)) return Infinity;
                    if (a.x === b.x && a.y > b.y && (v.y > a.y || v.y < b.y)) return Infinity;
                    return v.sub(origin).length();
                }
            }
            let _d = dist(origin, direction, p1, p2);
            if (_d < d) d = _d;
            _d = dist(origin, direction, p2, p3);
            if (_d < d) d = _d;
            _d = dist(origin, direction, p3, p4);
            if (_d < d) d = _d;
            _d = dist(origin, direction, p4, p1);
            if (_d < d) d = _d;

            return d;
        }
        //////////////////////////////////////////////////////////////////////////////
        const distanceRayToWall = (origin, direction, wall) => {

            const p1 = new Vector2(wall.innerLink.a.x, wall.innerLink.a.y);
            const p2 = new Vector2(wall.innerLink.b.x, wall.innerLink.b.y);
            const p3 = new Vector2(wall.parallelLink.a.x, wall.parallelLink.a.y);
            const p4 = new Vector2(wall.parallelLink.b.x, wall.parallelLink.b.y);
            let d = Infinity;

            const dist = (origin, direction, a, b) => {
                const a1 = -direction.y;
                const b1 = direction.x;
                const c1 = origin.x * (origin.y + direction.y) - (origin.x + direction.x) * origin.y;

                const a2 = a.y - b.y;
                const b2 = b.x - a.x;
                const c2 = a.x * b.y - b.x * a.y;

                const det = (a, b, c, d) => a * d - b * c;

                const zn = det(a1, b1, a2, b2)

                if (Math.abs(zn) < 0.0000001) return Infinity;
                else {
                    const v = new Vector2(
                        - det(c1, b1, c2, b2) / zn,
                        - det(a1, c1, a2, c2) / zn
                    );
                    //проверяем сторону луча
                    if (v.clone().sub(origin).dot(direction) < 0) return Infinity;
                    //проверяем что точка на отрезке
                    if (!checkPointLiesOnSegment(a, b, v)) return Infinity;
                    return v.sub(origin).length();
                }
            }
            let _d = dist(origin, direction, p1, p2);
            if (_d < d) d = _d;
            _d = dist(origin, direction, p2, p4);
            if (_d < d) d = _d;
            _d = dist(origin, direction, p3, p4);
            if (_d < d) d = _d;
            _d = dist(origin, direction, p1, p3);
            if (_d < d) d = _d;

            return d;
        }
        /////////////////////////////////////////////////////////////////////////
        const distNodeToNode = (n1, n2) => {
            return Math.sqrt((n1.x - n2.x) * (n1.x - n2.x) + (n1.y - n2.y) * (n1.y - n2.y));
        };
        ////////////////////////////////////////////////////////////////////////
        if (is_touch_device()) {
            canvas.addEventListener('touchstart', handlerDown);
            canvas.addEventListener('touchend', handlerUp);
            canvas.addEventListener('touchmove', handlerMove);
            canvas.addEventListener('touchcancel', handlerOut);

            canvas.addEventListener('wheel', handlerWheel, { passive: false });
            // canvas.addEventListener('contextmenu', handlerContext, false);
            canvas.addEventListener('redraw', handlerRedraw, false);
            canvas.addEventListener('planAnimation', handlerPlanAnimation, false);
            canvas.addEventListener('redrawSimple', handlerRedrawSimple, false);
            canvas.addEventListener('unselect', handlerUnselect, false);
            canvas.addEventListener('reselect', handlerReselect, false);
            window.addEventListener('resize', handlerResize, false);
        } else {
            canvas.addEventListener('mousedown', handlerDown);
            canvas.addEventListener('mouseup', handlerUp);
            canvas.addEventListener('mousemove', handlerMove);
            canvas.addEventListener('mouseout', handlerOut);

            canvas.addEventListener('wheel', handlerWheel, { passive: false });
            canvas.addEventListener('contextmenu', handlerContext, false);
            canvas.addEventListener('redraw', handlerRedraw, false);
            canvas.addEventListener('planAnimation', handlerPlanAnimation, false);
            canvas.addEventListener('redrawSimple', handlerRedrawSimple, false);
            canvas.addEventListener('unselect', handlerUnselect, false);
            canvas.addEventListener('reselect', handlerReselect, false);
            window.addEventListener('resize', handlerResize, false);
            window.addEventListener('keydown', handlerKeydown, false);
            window.addEventListener('keyup', handlerKeyUp, false);

        }
        canvas.addEventListener('centerModule', handlerCenterModule, false);
        canvas.addEventListener('selectObject', handlerSelectObject, false);

        // Prevent scrolling when touching the canvas
        document.body.addEventListener("touchstart", function (e) {
            if (!showMenu) return null;

            if (e.target !== canvas && e.target !== contextMenu.current) {
                setShowMenu(false);
            }
        }, false);
        document.body.addEventListener("touchend", function (e) {
            if (e.target == canvas) {
                // e.preventDefault();
            }
        }, false);

        let target = window;
        let last_y = 0;
        document.body.addEventListener("touchmove", function (e) {
            if (e.target == canvas) {
                // e.preventDefault();
            }
            let scrolly = target.pageYOffset || target.scrollTop || 0;
            let direction = e.changedTouches[0].pageY > last_y ? 1 : -1;
            if (direction > 0 && scrolly === 0) {
                // e.preventDefault();
            }
            last_y = e.changedTouches[0].pageY;
        }, false);

        // const animate = ()=> {
        //     requestAnimationFrame(animate);
        draw(ctx, plan);
        // }
        // animate();


        if (plan.restored) {
            dispatch(ProjectState.setEditMode(false));
            setMoveTool();
            plan.restored = false;
        } else {
            plan.canvasCenter = Convert.toSM(Convert.getCanvasCenterPixel());
        }


        if (plan.activeObject) {
            _setActiveObject({
                point: {},
                object: plan.activeObject.object
            });
            draw(ctx, plan);
        }

        return () => {
            if (is_touch_device()) {
                canvas.removeEventListener('touchstart', handlerDown);
                canvas.removeEventListener('touchend', handlerUp);
                canvas.removeEventListener('touchmove', handlerMove);
                canvas.removeEventListener('touchcancel', handlerOut);

                canvas.removeEventListener('wheel', handlerWheel, { passive: false });
                // canvas.removeEventListener('contextmenu', handlerContext, false);
                canvas.removeEventListener('redraw', handlerRedraw, false);
                canvas.removeEventListener('planAnimation', handlerPlanAnimation, false);
                canvas.removeEventListener('redrawSimple', handlerRedrawSimple, false);
                canvas.removeEventListener('unselect', handlerUnselect, false);
                canvas.removeEventListener('reselect', handlerReselect, false);
                window.removeEventListener('resize', handlerResize, false);
            } else {
                canvas.removeEventListener('mousedown', handlerDown);
                canvas.removeEventListener('mouseup', handlerUp);
                canvas.removeEventListener('mousemove', handlerMove);
                canvas.removeEventListener('mouseout', handlerOut);

                canvas.removeEventListener('wheel', handlerWheel, { passive: false });
                canvas.removeEventListener('contextmenu', handlerContext, false);
                canvas.removeEventListener('redraw', handlerRedraw, false);
                canvas.removeEventListener('planAnimation', handlerPlanAnimation, false);
                canvas.removeEventListener('redrawSimple', handlerRedrawSimple, false);
                canvas.removeEventListener('unselect', handlerUnselect, false);
                canvas.removeEventListener('reselect', handlerReselect, false);
                window.removeEventListener('resize', handlerResize, false);
                window.removeEventListener('keydown', handlerKeydown, false);
                window.removeEventListener('keyup', handlerKeyUp, false);
            }
            canvas.removeEventListener('centerModule', handlerCenterModule, false);
            canvas.removeEventListener('selectObject', handlerSelectObject, false);
        }

    }, [props.modules, props.editMode, props.plan, cancelTouch, tool, snap, steps, props.plan.cycleActive, props.plan.undoArr, props.plan.redoArr, ImageBGData, enableCreateWalls, showEstimated, level]);

    useEffect(() => {

        if (props.plan.nodes.length === 0 && !props.editMode) {
            dispatch(ProjectState.setEditMode(true));
            dispatch(ProjectState.setTool('move'));
            setActiveObject(null);
            props.plan.setActiveObject(null);
        }
        if (props.plan.cycles.length > 0 && props.editMode) {
            dispatch(ProjectState.setEditMode(false));
            dispatch(ProjectState.setTool('move'));
        }
        return () => {
            //dispatch(ProjectState.setPlan(props.plan));
        }
    }, [props.plan]);

    useEffect(() => {
        if (index !== null) {
            setActiveObject({ point: {}, object: modules[index] });
            props.plan.setActiveObject(_activeObject);
            index = null;
        }
    }, [updateFlag]);

    const modal = useSelector(store => store.project.modal);

    const removeNode = (node) => {
        props.plan.removeNode(node);
        sendRedrawEvent(ref.current);
        setActiveObject(null);
        props.plan.setActiveObject(null);
    }

    const handlerContextMenuClick = (value) => {
        if (!_activeObject) return;

        const { object } = _activeObject;
        const plan = props.plan;

        switch (value) {
            case 'Union': {
                if (object?.isWall) {
                    for (let i = 0; i < plan.bWalls.length; i++) {
                        const commonPoint = getCommonPoint(plan.bWalls[i].mainLink, object.mainLink);
                        const angle = calcAngleDeg(plan.bWalls[i].mainLink, object.mainLink);
                        if (object.mainLink !== plan.bWalls[i].mainLink && commonPoint && (angle < 1 || (angle > 179 && angle < 181))) {
                            plan.extendWall(plan.bWalls[i], object.mainLink);
                            plan.removeWall(object);
                            break;
                        }
                    }
                }
                setActiveObject(null);
                plan.setActiveObject(null);
                sendUnselectEvent(document.querySelector('#plan'))
                break;
            }
            case 'Resize': {
                if (object?.isColumn || object?.isModule) {
                    object.showSizeButtons = true;
                }
                break;
            }
            default: break;
        }

        sendRedrawSimpleEvent(document.querySelector('#plan'))
        sendRedrawEvent(ref.current);

        setShowMenu(false);
    }

    const modules = useSelector(store => store.modules.modules);

    const changeModule = (module, variant) => {
        index = modules.indexOf(module);
        dispatch({ type: 'CHANGE_MODULE', module, variant });
    }

    const styleImageBG = {
        opacity: ImageBGData.opacity / 100
    };
    if (ImageBGData.dataUrl) {
        styleImageBG.backgroundImage = 'url(' + ImageBGData.dataUrl + ')';
    }

    const menuItems = useMemo(() => {
        if (_activeObject?.object?.isColumn || _activeObject?.object?.isModule) {
            return [{ value: 'Resize', text: _activeObject?.object?.isColumn ? "Растянуть" : "Повернуть" }];
        } else if (_activeObject?.object?.isWall) {
            return [{ value: 'Union', text: "Объединить стены" }];
        }
        return [];
    }, [_activeObject]);

    const objectInfo = useMemo(() => {
        if (_activeObject?.object?.diagonalCenter) {
            return props.plan.cycles.find((cycle) => cycle.diagonalCenter === _activeObject?.object?.diagonalCenter)
        }
        return _activeObject?.object;
    }, [_activeObject?.object, props.plan.cycles]);

    return <>
        <canvas id="plan" ref={ref}></canvas>
        <canvas id="planFake" ref={refFake}></canvas>

        {modal === 'ImageBG' && <ImageBG />}
        <div ref={refCanvasImageBG} className={'canvasImageBG'} style={styleImageBG}></div>

        {object &&
            <EditField
                value={value}
                position={position}
                onChange={(value) => { onChange({ value }) }}
                onSubmit={(value) => {
                    if (object?.freeLink) {
                        props.plan.changeAngle(object.freeLink, object.links, object.angle, value);
                        sendRedrawSimpleEvent(document.querySelector('#plan'));
                        clearField();
                    }
                }}
            />
        }

        <ContextMenu
            ref={contextMenu}
            list={menuItems}
            show={showMenu}
            anchorPoint={anchorPoint}
            onClick={(value) => handlerContextMenuClick(value)}
        />

        {_activeObject !== null && _activeObject.object.isModule && modal !== 'estimateResult' &&
            <ModuleMenu
                module={_activeObject.object}
            />
        }
        {modal === 'moduleInfo' && _activeObject.object.isModule &&
            <ModuleInfo
                module={_activeObject.object}
                changeModule={changeModule}
            />
        }
        {_activeObject !== null && (_activeObject.object.wall || _activeObject.object.column) && _activeObject.object.obj > -1 && modal !== 'estimateResult' &&
            <WallObjectInfo
                parent={_activeObject.object.wall || _activeObject.object.column}
                objIndex={_activeObject.object.obj}
            />
        }
        {modal === 'projects' && <Projects />}
        {_activeObject !== null && _activeObject.object.type === 'node' && modal !== 'estimateResult' &&
            <PointInfo
                node={_activeObject.object.object}
                remove={removeNode}
            />
        }
        {_activeObject !== null && _activeObject.object.isCycle && (_activeObject.object.isFloor || _activeObject.object.isFigure) && modal !== 'estimateResult' &&
            <FloorInfo
                floor={_activeObject.object}
            />
        }
        {_activeObject !== null && (_activeObject.object.isWall && !_activeObject.object.isLink) && modal !== 'estimateResult' &&
            <BWallInfo
                plan={props.plan}
                wall={_activeObject.object}
            />
        }
        {_activeObject !== null && _activeObject?.object && _activeObject?.object.isColumn && modal !== 'estimateResult' &&
            <ColumnInfo
                plan={props.plan}
                column={_activeObject.object}
            />
        }
        {_activeObject !== null && !_activeObject.object.isCycle && (_activeObject.object.isRuler || _activeObject.object.isFigure || _activeObject.object.isLeader) && modal !== 'estimateResult' &&
            <LinkInfo
                plan={props.plan}
                link={_activeObject.object}
            />
        }
        {_activeObject !== null && _activeObject?.object && modal === 'estimate' &&
            <EstimateEdit
                object={_activeObject.object}
            />
        }
        {objectInfo && modal === 'info' &&
            <ObjectInfo
                obj={objectInfo}
            />
        }
        {_activeObject !== null && _activeObject.object && modal === 'estimateList' &&
            <EstimateObject
                object={_activeObject.object}
            />
        }
        <EstimateFormatterMode canvas={ref.current} />
    </>
}

const mapStateToProps = state => (
    {
        modules: state.modules.modules,
        editMode: state.project.editMode
    }
)

export default connect(mapStateToProps)(PlanEditor);
