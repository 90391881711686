import { Convert } from '../helpers/';
import Node from "../../../Classes/Node";

export const drawGrid = (ctx, offsetX, offsetY, zoom, ref, ImageBGData, refCanvasImageBG) => {

    const center = Convert.toPixel(new Node(0, 0), ctx, zoom, offsetX, offsetY) ;
    const cellSize = Math.ceil(1000 * zoom);
    const cellPosX = (center.x + 0) % cellSize;
    const cellPosY = (center.y + 0) % cellSize;
    ref.current.style.backgroundPosition = cellPosX + 'px ' + cellPosY + 'px';
    ref.current.style.backgroundSize = cellSize + 'px ' + cellSize + 'px';

    const bg_width = (ImageBGData.width * zoom) * (ImageBGData.scale / 100);
    const bg_height = (ImageBGData.width * zoom) * (ImageBGData.scale / 100);
    const bg_PosX = center.x - (bg_width / 2) + (ImageBGData.posX * zoom);
    const bg_PosY = center.y - (bg_height / 2) + (ImageBGData.posY * zoom);

    refCanvasImageBG.current.style.backgroundSize = bg_width + 'px';
    refCanvasImageBG.current.style.backgroundPosition = bg_PosX + 'px ' + bg_PosY + 'px';
}