import { drawHatches, drawPolylines, drawSizes } from './primitives';
import { rotatePoint, squaredDistance } from './geometry';
import { COLOR, drawObject } from './index';

export const drawColumns = (dxf, columns, walls) => {
    columns.forEach((column) => {
        const { x, y, width, depth, angle } = column;

        const a = rotatePoint({ x: x - width / 2, y: (y - depth / 2) }, { x, y }, -angle * 180 / Math.PI);
        const b = rotatePoint({ x: x - width / 2, y: (y + depth / 2) }, { x, y }, -angle * 180 / Math.PI);
        const c = rotatePoint({ x: x + width / 2, y: (y + depth / 2) }, { x, y }, -angle * 180 / Math.PI);
        const d = rotatePoint({ x: x + width / 2, y: (y - depth / 2) }, { x, y }, -angle * 180 / Math.PI);

        let parts = [a, b, c, d, a];
        if (column.noSizeSide === 'top') {
            parts = [a, b, c, d];
        } else if (column.noSizeSide === 'bottom') {
            parts = [c, d, a, b];
        }

        parts.forEach((part, index) => {
            if (parts?.[index + 1]) {
                drawSizes(dxf, part, parts[index + 1], { trueColor: COLOR.GRAY });
            }
        });

        column?.objects?.forEach((object) => drawObject(dxf, object, column, false));

        parts = [a, b, c, d, a];

        drawPolylines(dxf, { constantWidth: 0.2 }, parts);
        drawHatches(dxf, { trueColor: COLOR.GRAY }, parts);
    });

    const drawColumnSize = (cols, link, isReverse) => {
        const currentColumns = cols.map((col) => columns?.[col]).filter((column) => column);
        const columnsPoints = currentColumns.map((column) => {
            const offsetValue = column.noSizeSide === 'top' ? -1 : 1;
            return [
                rotatePoint(
                    { x: column.x - column.width / 2, y: column.y + offsetValue * column.depth / 2 },
                    column,
                    -column.angle * 180 / Math.PI
                ),
                rotatePoint(
                    { x: column.x + column.width / 2, y: column.y + offsetValue * column.depth / 2 },
                    column,
                    -column.angle * 180 / Math.PI
                )
            ]
        }).flat()
        const points = [link.a, link.b, ...columnsPoints].sort((a, b) => {
            return squaredDistance(link.a, isReverse ? a : b) - squaredDistance(link.a, isReverse ? b : a);
        })
        points.forEach((point, index) => {
            if (points?.[index + 1] && index % 2 === 0) {
                drawSizes(dxf, point, points[index + 1], { trueColor: COLOR.GRAY });
            }
        })
    }

    walls.forEach((wall) => {
        const isLeftWall = wall.mainLink.lrBuild === 'left';
        if (wall?.leftCols?.length) {
            drawColumnSize(wall.leftCols, isLeftWall ? wall.parallelLink : wall.innerLink, false);
        }
        if (wall?.rightCols?.length) {
            drawColumnSize(wall.rightCols, isLeftWall ? wall.innerLink : wall.parallelLink, true);
        }
    });
};
