import {
    HatchBoundaryPaths,
    HatchPolylineBoundary,
    HatchPredefinedPatterns,
    TextHorizontalAlignment,
    TextVerticalAlignment,
    pattern,
    point2d,
    point3d,
    vertex
} from '@tarikjabiri/dxf';
import { calculateAngle, squaredDistance } from './geometry';
import { DIM_SIZE } from './index';

export const drawPolylines = (dxf, options, ...parts) => {
    parts.forEach((part) => {
        const points = part.map((element) => ({ point: point2d(element.x, -element.y) }));
        if (points.length > 1) dxf.addLWPolyline(points, options);
    })
}

export const drawLine = (dxf, options, start, end) => {
    dxf.addLine(point2d(start.x, -start.y), point2d(end.x, -end.y), options);
}

export const drawSpline = (dxf, options, ...parts) => {
    parts.forEach((bezier) => {
        const controlPoints = bezier.points.map((point) => point2d(point.x, -point.y));
        dxf.addSpline({ controlPoints, options });
    })
}

export const drawHatches = (dxf, options, ...parts) => {
    const createHatchPolylineBoundary = (points) => {
        const hatch = new HatchPolylineBoundary();
        points.forEach((point) => {
            hatch.add(vertex(point.x, -point.y));
        });
        return hatch;
    }

    const hatchBoundary = new HatchBoundaryPaths();
    parts.forEach((part) => {
        hatchBoundary.addPolylineBoundary(createHatchPolylineBoundary(part));
    })

    const hatchPattern = pattern({ name: HatchPredefinedPatterns.SOLID });
    dxf.addHatch(hatchBoundary, hatchPattern, options);
}

export const drawSizes = (dxf, a, b, options) => {
    const isReverse = options?.isReverse || false;
    let offset = options?.offset ?? DIM_SIZE;

    const pointA = isReverse ? { x: b.x, y: -b.y } : { x: a.x, y: -a.y };
    const pointB = isReverse ? { x: a.x, y: -a.y } : { x: b.x, y: -b.y };

    const { angle, rotation } = calculateAngle(pointA, pointB);
    if (Math.abs(pointA.x - pointB.x) < 0.1) {
        offset = angle < -180 ? offset : -offset;
        dxf.addLinearDim(pointA, pointB, {
            offset,
            styleName: dxf.document.dimStyleStandard.name,
            angle,
            rotation,
            ...(options?.trueColor ? { trueColor: options?.trueColor } : {}),
        });
    } else {
        offset = (angle < 90 && angle > -90) ? -offset : offset;
        dxf.addAlignedDim(pointA, pointB, {
            offset,
            styleName: dxf.document.dimStyleStandard.name,
            rotation,
            ...(options?.trueColor ? { trueColor: options?.trueColor } : {}),
        })
    }
}

export const drawArc = (dxf, center, point, start, end, options = {}) => {
    const radius = Math.sqrt(squaredDistance(center, point));
    dxf.addArc({ x: center.x, y: -center.y }, radius, start, end, options);
}

export const drawCircle = (dxf, center, point) => {
    const radius = Math.sqrt(squaredDistance(center, point));
    dxf.addCircle({ x: center.x, y: -center.y }, radius);
}

export const drawText = (dxf, center, text, option) => {
    const size = option?.size || DIM_SIZE;
    const rotation = -option?.rotation || 0;

    dxf.addText(
        point3d(center.x, -center.y),
        size,
        text,
        {
            rotation,
            secondAlignmentPoint: point3d(center.x, -center.y),
            horizontalAlignment: option?.align ?? TextHorizontalAlignment.Center,
            verticalAlignment: TextVerticalAlignment.Middle,
        }
    );
}

export const drawImage = (dxf, image) => {
    return dxf.addImage(
        `./${image.name}.png`,
        image.name,
        point3d(image.x, image.y, 0),
        image.width,
        image.height,
        image.size.width,
        image.angle,
    );
}
