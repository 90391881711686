export const PUT_HELP_LINKS = 'MODULES/PUT_HELP_LINKS';
export const PUT_ALL_MODULES = 'MODULES/PUT_ALL_MODULES';
export const PUT_MODULES = 'MODULES/PUT_MODULES';
export const PUT_SECTIONS = 'MODULES/PUT_SECTIONS';
export const ADD_MODULE = 'MODULES/ADD_MODULE';
export const REMOVE_MODULE = 'MODULES/REMOVE_MODULE';
export const ADD_INSTANCE_MODULE = 'MODULES/ADD_INSTANCE_MODULE';
export const CLEAR_INSTANCE_MODULE = 'MODULES/CLEAR_INSTANCE_MODULE';
export const UPDATE_MODULE_FLAG = 'MODULES/UPDATE_MODULE_FLAG';
export const ADD_CASH = 'MODULES/ADD_CASH';
export const GET_PRICE = 'MODULES/GET_PRICE';
export const SET_PRICE = 'MODULES/SET_PRICE';




const InitialState = {
    modules: [],
    sections: [],
    allModules: [],
    helpLinks: [],
    updateModuleFlag: true,
    price: 0,
    modulePrice: {},
    locationInRooms: { up: undefined, right: undefined, bottom: undefined, left: undefined }
};

export const actionsState = {
    putHelpLinks: (value) => {
        return { type: PUT_HELP_LINKS, value }
    },
    putModules: (value) => {
        return { type: PUT_MODULES, value }
    },
    putAllModules: (value) => {
        return { type: PUT_ALL_MODULES, value }
    },
    putSections: (value) => {
        return { type: PUT_SECTIONS, value }
    },
    addModule: (module, canvasCenter, refModule) => {
        return { type: ADD_MODULE, module, canvasCenter, refModule }
    },
    remove: (module) => {
        return { type: REMOVE_MODULE, module }
    },
    addInstanceModule: (m) => {
        return { type: ADD_INSTANCE_MODULE, module: m }
    },
    clearInstanceModules: () => {
        return { type: CLEAR_INSTANCE_MODULE }
    },
    addCash: (module) => {
        return { type: ADD_CASH, module }
    },
    updateModule: () => {
        return { type: UPDATE_MODULE_FLAG }
    },
    getPrice: () => {
        return { type: GET_PRICE }
    },
    setPrice: (price, modulePrice) => {
        return { type: SET_PRICE, price, modulePrice }
    }
};

const reducer = (state = InitialState, action) => {
    switch (action.type) {
        case PUT_HELP_LINKS: {
            return {
                ...state,
                helpLinks: action.value
            }
        }
        case PUT_MODULES: {
            return {
                ...state,
                modules: action.value
            }
        }
        case PUT_ALL_MODULES: {
            return {
                ...state,
                allModules: action.value
            }
        }
        case PUT_SECTIONS: {
            return {
                ...state,
                sections: action.value
            }
        }
        case ADD_INSTANCE_MODULE: {
            const newModules = [...state.modules];
            newModules.push(action.module)
            return {
                ...state,
                modules: newModules
            }
        }
        case CLEAR_INSTANCE_MODULE: {
            return {
                ...state,
                modules: []
            }
        }
        case ADD_CASH: {
            const newCash = { ...state.cash }
            newCash[action.module.ID] = action.module;
            return {
                ...state,
                cash: newCash
            }
        }
        case REMOVE_MODULE: {
            const newModules = state.modules.filter(m => m !== action.module);
            return {
                ...state,
                modules: newModules
            }
        }
        case UPDATE_MODULE_FLAG: {
            return {
                ...state,
                updateModuleFlag: !state.updateModuleFlag
            }
        }
        case SET_PRICE: {
            return {
                ...state,
                price: action.price,
                modulePrice: action.modulePrice
            }
        }


        default: {
            return state;
        }
    }
};

export default reducer;
