import { drawHatches, drawLine, drawPolylines, drawSizes, drawSpline } from './primitives';
import { squaredDistance } from './geometry';
import { drawObject } from './drawObject';
import { Bezier } from '../../../../../Helpers/Bezier';
import { COLOR, getNextIndex, settingsDxf } from './index';

const getWallParams = (walls, currentPoint, nextPoint) => {
    let mainPoint = null;
    let parallelPoint = null;
    let nextMainPoint = null;
    let nextParallelPoint = null;
    let currentWall = null;
    let isReverse = false;
    let objects = [];
    let hasColumn = false;

    walls.forEach((wall) => {
        if (
            wall.innerLink.a.x === currentPoint.x
            && wall.innerLink.a.y === currentPoint.y
            && wall.innerLink.b.x === nextPoint.x
            && wall.innerLink.b.y === nextPoint.y
        ) {
            currentWall = wall;
            isReverse = wall.mainLink.lrBuild === 'right';
            mainPoint = wall.innerLink.a;
            parallelPoint = wall.parallelLink.a;
            nextMainPoint = wall.innerLink.b;
            nextParallelPoint = wall.parallelLink.b;
            hasColumn = (currentWall?.leftCols?.length || currentWall?.rightCols?.length);
            objects = [...wall.objects].sort((a, b) => {
                return squaredDistance(mainPoint, a.objA) - squaredDistance(mainPoint, b.objA);
            })
        }
        if (
            wall.innerLink.b.x === currentPoint.x
            && wall.innerLink.b.y === currentPoint.y
            && wall.innerLink.a.x === nextPoint.x
            && wall.innerLink.a.y === nextPoint.y
        ) {
            currentWall = wall;
            isReverse = wall.mainLink.lrBuild === 'left';
            mainPoint = wall.innerLink.b;
            parallelPoint = wall.parallelLink.b;
            nextMainPoint = wall.innerLink.a;
            nextParallelPoint = wall.parallelLink.a;
            hasColumn = (currentWall?.leftCols?.length || currentWall?.rightCols?.length);
            objects = [...wall.objects].sort((a, b) => {
                return squaredDistance(mainPoint, a.objB) - squaredDistance(mainPoint, b.objB);
            }).map((object) => ({
                ...object,
                rB1Inner: object.rA1Inner,
                rA1Inner: object.rB1Inner,
                rB2Inner: object.rA2Inner,
                rA2Inner: object.rB2Inner,
                ...(object?.rA13d ? { rB13d: object?.rA13d } : {}),
                ...(object?.rB13d ? { rA13d: object?.rB13d } : {}),
                ...(object?.rA23d ? { rB23d: object?.rA23d } : {}),
                ...(object?.rB23d ? { rA23d: object?.rB23d } : {}),
            }));
        }
    });

    return {
        isReverse,
        mainPoint,
        parallelPoint,
        nextMainPoint,
        nextParallelPoint,
        objects,
        currentWall,
        hasColumn
    }
}

export const drawPaths = (dxf, points, walls) => {
    const mainPoints = [];
    const parallelPoints = [];
    const curvesGroups = [];
    const currentWalls = [];

    for (let index = 0; index < points.length; index++) {
        const currentPoint = points[index];
        const nextIndex = getNextIndex(points, index);
        const nextPoint = points[nextIndex];

        if (currentPoint.x === nextPoint.x && currentPoint.y === nextPoint.y) continue;

        const {
            currentWall,
            mainPoint,
            parallelPoint,
            nextMainPoint,
            nextParallelPoint,
            objects,
            isReverse,
            hasColumn
        } = getWallParams(walls, currentPoint, nextPoint);

        if (currentWall === null) {
            mainPoints.push(currentPoint);
            parallelPoints.push(parallelPoints[parallelPoints.length - 1]);
        } else {
            const drawSizesOptions = { isReverse, trueColor: COLOR.GRAY };

            mainPoints.push(mainPoint);
            parallelPoints.push(parallelPoint);

            if (points.length === 2 && index === 1) {
                mainPoints.push('connect');
                parallelPoints.push('connect');
                mainPoints.unshift('connect');
                parallelPoints.unshift('connect');
            }

            if (index === 0 || points.length > 2) {
                currentWalls.push(currentWall);

                const holeObjects = objects.filter((object) => (
                    object?.isWindow
                    || object?.isHole
                    || object?.isDoor
                ));
                const wallObjects = objects.filter((object) => (
                    object?.isElectricSocket
                    || object?.isSwitch
                    || object?.isHeatingBattery
                    || object?.isElectricPanel
                    || object?.isRedCube
                    || object?.isCylinder
                    || object?.outletElectricalWire
                ));

                wallObjects.forEach((object) => {
                    if (settingsDxf.showElectricity) {
                        drawObject(dxf, object, currentWall, isReverse);
                    } else if (!object?.isElectricSocket && !object?.isSwitch && !object?.outletElectricalWire && !object?.isElectricPanel) {
                        drawObject(dxf, object, currentWall, isReverse);
                    }
                });

                holeObjects.forEach((object) => {
                    drawObject(dxf, object, currentWall, isReverse);
                });

                if (currentWall?.isBezier) {
                    const curveGroup = [];
                    curveGroup.push(new Bezier(currentWall.bezier.points));
                    const reverseOutlineBezier = [...currentWall.outlineBezier].reverse();
                    reverseOutlineBezier.forEach((bezier) => {
                        curveGroup.push(new Bezier(bezier.points));
                    });

                    curvesGroups.push(curveGroup);
                    mainPoints.push('gap');
                    parallelPoints.push('gap');

                    drawSizes(dxf, currentPoint, nextPoint, drawSizesOptions);
                }

                if (!currentWall?.isBezier) {
                    holeObjects.forEach((object, objectIndex) => {
                        const previousObjects = holeObjects.slice(0, objectIndex);
                        const findLastObjectIndex = previousObjects
                            .reverse()
                            .findIndex((prevObject) => (
                                prevObject?.isWindow || prevObject?.isHole || prevObject?.isDoor
                            ));

                        if (!hasColumn) {
                            if (findLastObjectIndex === -1) {
                                drawSizes(dxf, mainPoint, object.rA13d, drawSizesOptions);
                            } else {
                                drawSizes(dxf, mainPoints[(mainPoints.length - 1) - findLastObjectIndex], object.rA13d, drawSizesOptions);
                            }
                            drawSizes(dxf, object.rA13d, object.rB13d, drawSizesOptions);
                        }

                        mainPoints.push(object.rA13d);
                        parallelPoints.push(object.rA23d);

                        mainPoints.push('connect');
                        parallelPoints.push('connect');

                        mainPoints.push(object.rB13d);
                        parallelPoints.push(object.rB23d);

                        if (objectIndex === holeObjects.length - 1 && !hasColumn) {
                            drawSizes(dxf, object.rB13d, nextPoint, drawSizesOptions);
                        }
                    })

                    if (holeObjects.length === 0 && !hasColumn) {
                        drawSizes(dxf, currentPoint, nextPoint, drawSizesOptions);
                    }
                }

                mainPoints.push(nextMainPoint);
                parallelPoints.push(nextParallelPoint);
            }
        }
    }

    if (mainPoints.length && parallelPoints.length) {
        const polylines = [];
        collectPaths(mainPoints, parallelPoints).forEach((part) => polylines.push(part));

        if (settingsDxf.algorithm === 'walls') {
            polylines.forEach((polyline) => {
                polyline.forEach((point, index) => {
                    if (index < polyline.length - 1) {
                        const currentPoint = polyline[index];
                        const nextPoint = polyline[getNextIndex(polyline, index)];
                        drawLine(dxf, { constantWidth: 0.2 }, currentPoint, nextPoint);
                    }
                })
            })

            const subdividePath = (points) => {
                return points.reduce((acc, point, index, array) => {
                    acc.push(point);
                    if ((index + 1) % 2 !== 0) {
                        acc.push('connect');
                        acc.push(point)
                    }
                    return acc;
                }, []);
            }

            const hatches = [];
            collectPaths(
                subdividePath(mainPoints.map((p) => p === 'gap' ? 'connect' : p)),
                subdividePath(parallelPoints.map((p) => p === 'gap' ? 'connect' : p))
            ).forEach((part) => hatches.push(part));
            if (hatches.length) hatches.forEach((hatch) => drawHatches(dxf, { trueColor: COLOR.GRAY }, hatch))
        } else {
            if (polylines.length) drawPolylines(dxf, { constantWidth: 0.2 }, ...polylines);

            const hatches = [];
            collectPaths(
                mainPoints.map((p) => p === 'gap' ? 'connect' : p),
                parallelPoints.map((p) => p === 'gap' ? 'connect' : p)
            ).forEach((part) => hatches.push(part));

            if (hatches.length) drawHatches(dxf, { trueColor: COLOR.GRAY }, ...hatches);
        }
    }

    if (curvesGroups.length) {
        const curveHatches = [];
        curvesGroups.forEach((group) => {
            const hatchesGroup = [];
            group.forEach((bezier, index) => {
                const LUTs = bezier.getLUT(Math.max(10, Math.ceil(bezier.length() / 100)));
                const result = index === 0 ? LUTs : [...LUTs].reverse();
                hatchesGroup.push(...result);
            });
            curveHatches.push(hatchesGroup);
        })
        drawSpline(dxf, {}, ...curvesGroups.flat());
        drawHatches(dxf, { trueColor: COLOR.GRAY }, ...curveHatches);
    }

    return { currentWalls };
}

export const collectPaths = (mainPoints, parallelPoints) => {
    const sortedArray = (array) => {
        const lastConnectIndex = array.lastIndexOf('connect');
        const lastGapIndex = array.lastIndexOf('gap');
        if (lastConnectIndex === -1 && lastGapIndex === -1) return array;

        const lastBreakIndex = Math.max(lastConnectIndex, lastGapIndex);

        const afterLastBreak = array.slice(lastBreakIndex + 1);
        const beforeLastBreak = array.slice(0, lastBreakIndex + 1);
        return [array[lastBreakIndex], ...afterLastBreak, ...beforeLastBreak];
    }

    const splitSegments = (points) => {
        const segments = [];
        let currentSegment = [];
        let startType = null;

        points.forEach((point) => {
            if (point === 'connect' || point === 'gap') {
                if (currentSegment.length > 0) {
                    segments.push({ segment: currentSegment, start: startType, end: point });
                    currentSegment = [];
                }
                startType = point;
            } else {
                currentSegment.push(point);
            }
        });

        if (currentSegment.length > 0) {
            segments.push({segment: currentSegment, start: startType, end: null});
        }

        return segments;
    };

    const mainSegments = splitSegments(sortedArray(mainPoints));
    const parallelSegments = splitSegments(sortedArray(parallelPoints));

    const paths = [];
    mainSegments.forEach((main, index) => {
        if (main.start === null && main.end === null) {
            paths.push([...main.segment, main.segment[0]]);
            paths.push([...parallelSegments[index].segment, parallelSegments[index].segment[0]]);
        } else if (main.start === 'connect' && main.end === 'connect') {
            const path = [...main.segment, ...parallelSegments[index].segment.reverse(), main.segment[0]];
            paths.push(path);
        } else if (main.start === 'connect' && main.end === 'gap') {
            const path = [...main.segment.reverse(), ...parallelSegments[index].segment];
            paths.push(path);
        } else if (main.start === 'gap' && main.end === 'connect') {
            const path = [...main.segment, ...parallelSegments[index].segment.reverse()];
            paths.push(path);
        } else if (main.start === 'gap' && main.end === 'gap') {
            paths.push(main.segment);
            paths.push(parallelSegments[index].segment);
        }
    });

    const roundToPrecision = (value, precision) => {
        const factor = Math.pow(10, precision);
        return Math.round(value * factor) / factor;
    };

    const filterPaths = (paths, precision) => {
        return paths.filter((path) => {
            const uniquePoints = new Set(path.map(point =>
                `${roundToPrecision(point.x, precision)},${roundToPrecision(point.y, precision)}`
            ));
            return uniquePoints.size > 2;
        });
    };

    const precision = 5;
    return  filterPaths(paths, precision);
}
